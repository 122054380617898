import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import ResultDlgBox from "../../components/common-components/result-popup";
import doneIcon from "../../assets/icons/done_icon.svg";
import failIcon from "../../assets/icons/fail_icon.svg";
import ConfirmationDlgBox from "../../components/common-components/confirmation-popup";
import ApiClient from "../../apis/Api-client";
import { END_POINT } from "../../utils/End-points";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./syncFloorRoom.scss";
import { toast } from "react-toastify";
import IconConstants from "../../utils/IconConstants";
import { useNavigate } from "react-router-dom";
import { successToster } from "../../shared/toster/Toster";
import { format } from "date-fns";
import AuthorizedComponent from "../../components/auth/authorization/AuthorizeComponent";
import { USER_ROLES } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
const { ADMIN, RECEPTIONIST } = USER_ROLES;

const SyncFloorRoom = () => {
  const { api } = ApiClient();
  const [floorData, setFloorData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isDraggDisabled, setisDraggDisabled] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    getAllFloors();
  }, []);

  const getAllFloors = async () => {
    try {
      // Make the API call to get floor data
      const res = await api.get(
        `${END_POINT.FLOOR.GET_ALL_FLOORS}/${format(new Date(), "yyyy-MM-dd")}`,
        "",
        "Unable to get rooms"
      );

      if (res.status === 200) {
        // Process the floor data
        const updatedFloorData = await Promise.all(
          res.data.data.map(async (floor) => {
            // Filter rooms by position
            const roomStatus = await filterRoomsByPosition(floor.rooms);

            // Check and update room positions if bottom is empty
            if (roomStatus.bottom.items.length === 0) {
              updateRoomPositions(roomStatus);
            }

            // Return updated floor data
            return {
              floor_id: floor.floor_id,
              floor_name: floor.floor_name,
              floor_code: floor.floor_code,
              roomStatus: roomStatus,
            };
          })
        );

        // Set the updated floor data
        setFloorData(updatedFloorData);
      }
    } catch (error) {
      // Handle error
    }
  };

  const filterRoomsByPosition = async (rooms) => {
    // Initialize roomStatus
    const roomStatus = {
      top: { name: "Top", items: [] },
      bottom: { name: "Bottom", items: [] },
    };

    // Filter rooms based on position
    rooms.forEach((room) => {
      const position = room?.position.toLowerCase();
      roomStatus[position]?.items?.push(room);
    });

    return roomStatus;
  };

  const updateRoomPositions = (roomStatus) => {
    // Initialize updatedRoomStatus
    const updatedRoomStatus = {
      top: { name: "Top", items: [] },
      bottom: { name: "Bottom", items: [] },
    };

    // Update room positions
    roomStatus.top.items.forEach((room, index) => {
      const position = index % 2 === 0 ? "top" : "bottom";
      room.position = position;
      updatedRoomStatus[position].items.push(room);
    });

    // Assign updated room positions to roomStatus
    Object.assign(roomStatus, updatedRoomStatus);
  };

  const [resultIcon, setResultIcon] = useState(doneIcon);
  const [resultLabel, setResultLabel] = useState(
    "Floor & Room view Configured Successfully"
  );
  const [resultDlg, setResultDlg] = useState(false);
  const [confirmationDlg, setConfirmationDlg] = useState(false);
  const [confirmationLabel, setConfirmationLabel] = useState(
    "Are you sure you want to delete room type single ?"
  );

  const onDragEnd = (result, roomStatus, setFloorData) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (
      destination?.droppableId === source?.droppableId &&
      destination?.index === source?.index
    )
      return;

    // Find the source and destination columns based on droppableId
    const sourceColumn = roomStatus[source.droppableId];
    const destColumn = roomStatus[destination.droppableId];

    // Create copies of the source and destination items arrays
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    // Remove the dragged item from the source column
    const updatedSourceArray = sourceItems.filter(
      (obj) => obj.room_type_id !== result?.draggableId
    );

    // Insert the dragged item into the destination column at the specified index

    // Update the roomStatus for the floor
    let updatedRoomStatus;
    if (destination?.droppableId === source?.droppableId) {
      const [removed] = destItems.splice(source.index, 1);
      removed.position_index = destination.index;
      removed.position = destination?.droppableId;

      destItems.splice(destination.index, 0, removed);
      destItems.forEach((obj, index) => (obj.position_index = index));

      updatedRoomStatus = {
        ...roomStatus,
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
    } else {
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      removed.position_index = destination.index;
      removed.position = destination?.droppableId;

      destItems.forEach((obj, index) => (obj.position_index = index));
      updatedRoomStatus = {
        ...roomStatus,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
    }
    // Update the entire floorData with the new roomStatus
    const updatedFloorData = floorData.map((floor) => {
      if (floor.roomStatus === roomStatus) {
        return {
          ...floor,
          roomStatus: updatedRoomStatus,
        };
      }
      return floor;
    });

    // Set the updated floorData
    setFloorData(updatedFloorData);
  };
  const mergeItemsArrays = (obj) => {
    return [...obj.roomStatus.top.items, ...obj.roomStatus.bottom.items];
  };

  const handleFloorUpdate = async () => {
    try {
      const updatedRooms = [].concat(...floorData.map(mergeItemsArrays));

      let updateRes = await api.post(
        END_POINT.ROOM.ADD_OR_UPDATE_ROOMS,
        { data: updatedRooms },
        "Unable to update rooms"
      );

      if (updateRes?.status == 200) {
        successToster(
          t("pages.facility_settings.toast.floor_room_config_success")
        );
        setIsEdit(false);
        setisDraggDisabled(true);
      }
    } catch (error) {
      successToster(t("pages.facility_settings.error_while_floor_config"));
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={11.1}
        md={9}
        sx={{
          margin: "3% auto 1% auto",
          borderRadius: "11px",
          backgroundColor: isEdit ? "#f9f9f9" : "#fffdf5",
          border: `1px solid ${isEdit ? "#B8B8B8" : "#FFE097"}`,
          padding: "2.5% 0",
          minHeight: "80vh",
        }}
      >
        <Box sx={{ width: "95%", marginBottom: "5%", textAlign: "right" }}>
          <Button
            sx={{
              marginRight: "auto",
              fontWeight: 500,
              fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.83vw" },
              color: "#CE8500",
              backgroundColor: "#FFF9EA",
              "&:hover": {
                backgroundColor: "#FFF9EA",
              },
              textTransform: "none",
              marginRight: "2%",
            }}
            variant="outlined"
            onClick={() => navigate("/main/facilitysettings")}
            startIcon={<img src={IconConstants.RoomView} alt="" />}
          >
            {t("pages.facility_settings.switch_to_room_view")}
          </Button>

          <AuthorizedComponent authorizedRoles={[ADMIN]}>
            <Button
              sx={{
                marginRight: "auto",
                fontWeight: 500,
                fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.83vw" },
                color: isEdit ? "#F9F9F9" : "#CE8500",
                backgroundColor: isEdit ? "#DFA827" : "#FFF9EA",
                "&:hover": {
                  backgroundColor: isEdit ? "#DFA827" : "#FFF9EA",
                },
                textTransform: "none",
              }}
              variant="outlined"
              onClick={() => {
                setIsEdit(true);
                setisDraggDisabled(false);
              }}
              startIcon={
                <img
                  src={
                    isEdit
                      ? IconConstants.PencilWhite
                      : IconConstants.PencilEdit
                  }
                  alt=""
                />
              }
            >
              {t("pages.facility_settings.edit")}
            </Button>
          </AuthorizedComponent>
        </Box>
        {floorData?.map((floor, floorIndex) => (
          <>
            <Box key={floorIndex}>
              <Box
                sx={{
                  width: "98%",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 auto",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "10%", sm: "8%" },
                    textAlign: "center",
                    marginTop: "5%",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      height: { xs: "8.21vw", sm: "5.76vw", md: "3.33vw" },
                      width: { xs: "8.21vw", sm: "5.76vw", md: "3.33vw" },
                      background:
                        "linear-gradient(180deg, #DAA72E 0%, rgba(218, 167, 46, 0) 303.13%)",
                      borderRadius: { xs: "4px", sm: "8px" },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0 auto",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: { md: "1.67vw", sm: "2.88vw", xs: "4.1vw" },
                        fontWeight: 700,
                      }}
                    >
                      {floor.floor_code}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ width: { xs: "88%", sm: "90%" } }}>
                  <DragDropContext
                    onDragEnd={(result) =>
                      onDragEnd(result, floor.roomStatus, setFloorData)
                    }
                  >
                    {Object.entries(floor.roomStatus).map(
                      ([columnId, column], index) => {
                        return (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            key={columnId}
                          >
                            <div style={{ width: "100%" }}>
                              <Droppable
                                droppableId={columnId}
                                key={columnId}
                                direction="horizontal"
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <Box
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      sx={{
                                        width: { xs: "78vw", md: "52vw" },
                                        display: "flex",
                                        overflowX: "scroll",
                                        minHeight: "3vw",
                                      }}
                                    >
                                      {column.items
                                        .sort(
                                          (a, b) =>
                                            a?.position_index -
                                            b?.position_index
                                        )
                                        .map((item, index) => {
                                          return (
                                            <Draggable
                                              key={item.room_id}
                                              draggableId={`${item.room_id}`}
                                              index={index}
                                              isDragDisabled={isDraggDisabled}
                                            >
                                              {(provided, snapshot) => {
                                                return (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                      userSelect: "none",
                                                      margin: "0 6.5% 0 0",
                                                      ...provided.draggableProps
                                                        .style,
                                                    }}
                                                  >
                                                    <Box
                                                      sx={{
                                                        textAlign: "center",
                                                        width: {
                                                          xs: "8.25vw",
                                                          sm: "4.79vw",
                                                          md: "2.78vw",
                                                        },
                                                        height: {
                                                          xs: "8.25vw",
                                                          sm: "4.79vw",
                                                          md: "2.78vw",
                                                        },
                                                        backgroundColor:
                                                          "#62BA60",
                                                        borderRadius: {
                                                          xs: "4px",
                                                          sm: "6px",
                                                        },
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      <Typography
                                                        sx={{
                                                          color: "#FFFFFF",
                                                          fontSize: {
                                                            md: "0.97vw",
                                                            sm: "1.68vw",
                                                            xs: "3.08vw",
                                                          },
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        {item.room_no}
                                                      </Typography>
                                                    </Box>
                                                  </div>
                                                );
                                              }}
                                            </Draggable>
                                          );
                                        })}
                                      {provided.placeholder}
                                    </Box>
                                  );
                                }}
                              </Droppable>
                              {index % 2 == 0 && (
                                <Box
                                  sx={{
                                    border: "2px solid #D6D6D6",
                                    width: "100%",
                                    borderRadius: "8px",
                                    margin: "2.5% 0",
                                  }}
                                ></Box>
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </DragDropContext>
                  {floorData.length - 1 != floorIndex && (
                    <Box
                      sx={{
                        width: "100%",
                        border: "1px solid #FFE097",
                        margin: "5% 0",
                      }}
                    ></Box>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        ))}

        <Box
          sx={{
            width: "100%",
            display: isEdit ? "block" : "none",
          }}
        >
          <Box
            sx={{
              width: "95%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "2vw",
              margin: { xs: "8% 0 0 0", sm: "2% 0" },
            }}
          >
            <Button
              sx={{
                fontWeight: 600,
                padding: "1.3% 7%",
                fontSize: { xs: "3.08vw", sm: "1.68vw", md: "0.98vw" },
                color: (theme) => theme.palette.orange_theme.primary.main,
                border: `1px solid ${(theme) =>
                  theme.palette.orange_theme.primary.main}`,
              }}
              variant="outlined"
              onClick={() => {
                setIsEdit(false);
                setisDraggDisabled(true);
                getAllFloors();
              }}
            >
              {t("pages.facility_settings.room_number_form.cancel")}
            </Button>
            <Button
              sx={{
                fontWeight: 600,
                paddingX: "7%",
                padding: "1.4% 7%",
                fontSize: { xs: "3.08vw", sm: "1.68vw", md: "0.98vw" },
                color: (theme) =>
                  theme.palette.orange_theme.primary.contrastText,
                backgroundColor: (theme) =>
                  theme.palette.orange_theme.primary.main,
              }}
              variant="contained"
              onClick={handleFloorUpdate}
            >
              {t("pages.facility_settings.room_number_form.update")}
            </Button>
          </Box>
        </Box>
      </Grid>
      <ResultDlgBox
        open={resultDlg}
        infoIcon={resultIcon}
        setOpen={setResultDlg}
        label={resultLabel}
        timeout={5000}
        closeDlgBox={() => setResultDlg(false)}
      />
    </Grid>
  );
};

export default SyncFloorRoom;
