import { combineReducers } from 'redux';
import rooms from './slices/Rooms-slice';
import loader from './slices/Loader-slice';
import roomType from './slices/RoomType-slice';
import checkOut from './slices/CheckOut-slice';
import reservation from './slices/Reservation-slice'
import dashBoard from './slices/Dashboard-slice'
import checkin from './slices/Checkin-slice'
import dashboardApi from "./slices/DashboardApi-slice"
import updateProfile from './slices/UpdateProfile-slice'
import auth from './slices/Auth-slice'
import languageSlice from './slices/Language-slice'

const rootReducer = combineReducers({
    loader, rooms, roomType, checkOut, reservation, dashBoard, checkin, dashboardApi, updateProfile, auth, languageSlice
});

export default rootReducer;