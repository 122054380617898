import { useEffect } from "react";
import LoginForm from "../../../components/auth/login/Login-component";
import { languages } from "../../../components/common-components/LanguageSwitcher";
import i18n from "../../../config/localization/localize";

export const Login = () => {
  useEffect(() => {
    // Detect the browser language
    const browserLanguage = navigator.language || "en-US";
    const defaultLanguage =
      languages.find((lang) => browserLanguage.startsWith(lang.code))?.code ||
      "en";

    // Set the detected or default language
    i18n.changeLanguage(defaultLanguage);
    localStorage.setItem("language", defaultLanguage);
  }, []);
  return <LoginForm />;
};
