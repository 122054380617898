import React, { useEffect, useState } from "react";
import { Box, Grid, Badge, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import { format, startOfWeek } from "date-fns";
import moment from "moment";
import Holidays from "date-holidays";
import IconConstants from "../../utils/IconConstants";
import ApiClient from "../../apis/Api-client";
import { END_POINT } from "../../utils/End-points";
import {
  OCCUPANCY_STATUSES,
  RESERVATION_STATUSES,
} from "../../utils/Constants";
import { useDispatch } from "react-redux";
import { setDashBoardData } from "../../core/store/redux/slices/Dashboard-slice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../core/store/redux/slices/Language-slice";
import axios from "axios";
import dayjs from "dayjs";

const { CHECK_IN } = OCCUPANCY_STATUSES;
const { CONFIRMED, TENTATIVE } = RESERVATION_STATUSES;

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const StyledGrid = styled(Grid)(({ theme }) => ({
  border: "1px solid #FFDC8A",
  borderRadius: "12px",
  overflow: "hidden",
}));

const HeaderRow = styled(Grid)(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "#FFFBF1",
}));

const WrapperBox = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  boxSizing: "border-box",
}));

const DayCell = styled(Box)(({ hasEvent, isToday, isOutsideMonth }) => ({
  border: isToday ? "1.5px dashed #DEA829" : "0.8px solid #FCF0D4",
  backgroundColor: hasEvent ? "#FFFBF1" : isToday ? "#F4F4F4" : "#FFFFFF",
  position: "relative",
  textAlign: "left",
  color: "#5D5D5D",
  opacity: isOutsideMonth ? 0.5 : 1,
}));

const MonthView = ({
  selectedDate = new Date(),
  isExpanded,
  setView,
  setStartFrom,
  setMonth,
  isEnglish,
}) => {
  const { api } = ApiClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const language =
    useSelector(selectLanguage) || localStorage.getItem("language");
  const isIndia = language === "en" || language === "hi";
  const daysInMonth = moment(selectedDate).daysInMonth();
  const startOfMonth = moment(selectedDate).startOf("month");
  const weeksInMonth = Math.ceil((daysInMonth + startOfMonth.day()) / 7);

  useEffect(() => {
    const fetchHolidays = async () => {
      if (!selectedDate) return;
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_S3_URL
          }/holidays/india/${selectedDate.getFullYear()}.json`
        );
        setHolidays(response?.data);
      } catch (err) {
        setHolidays([]);
      }
    };

    if (isIndia) {
      fetchHolidays();
    } else {
      const hd = new Holidays(language);
      const holidaysData = hd.getHolidays(selectedDate?.getFullYear());

      if (holidaysData) {
        setHolidays(holidaysData);
      } else {
        setHolidays([]);
      }
    }
  }, [selectedDate, language, isIndia]);

  useEffect(() => {
    getAllBookings();
  }, []);

  const getAllBookings = async () => {
    try {
      const response = await api.post(END_POINT.BOOKING.GET_ALL_BOOKING, {});
      if (response.status === 200) {
        const { data } = response?.data;
        if (data.length) {
          const updatedData = data.map((v) => {
            const localCheckInTime = moment(v.checkin_date).toDate();
            const localCheckoutTime = moment(v.checkout_date).toDate();
            return {
              ...v,
              start:
                v.booking_status === CHECK_IN
                  ? localCheckoutTime
                  : localCheckInTime,
              end: moment(
                v.booking_status === CHECK_IN
                  ? localCheckoutTime
                  : localCheckInTime
              )
                .add(2, "hours")
                .toDate(),
              status: v.booking_status,
            };
          });
          setEvents(updatedData);
        }
      }
    } catch (error) {}
  };

  const handleNavigateToWeek = (day) => {
    const weekStartsOn = day.getDay();
    const alignedStartOfWeek = startOfWeek(day, { weekStartsOn });
    setStartFrom(alignedStartOfWeek);
    setMonth(dayjs(alignedStartOfWeek));
    setView("Week");
    localStorage.setItem("startFrom", alignedStartOfWeek.toISOString());
    localStorage.setItem("calenderView", "Week");
    localStorage.setItem("month", dayjs(alignedStartOfWeek));
  };

  const renderDayCell = (day, isOutsideMonth) => {
    const dayEvents = events.filter((e) => moment(e.start).isSame(day, "day"));

    const reservationsCount = dayEvents.filter((e) =>
      [CONFIRMED, TENTATIVE].includes(e.status)
    ).length;

    const expectedCheckoutsCount = dayEvents.filter(
      (e) => e.status === CHECK_IN
    ).length;

    const handleIconClick = (path, date) => {
      const selectedDate = format(new Date(date), "yyyy-MM-dd");
      dispatch(
        setDashBoardData({
          expectedCheckOutDate: selectedDate,
          expectedCheckInDate: selectedDate,
        })
      );
      navigate(`/main/${path}`);
    };

    const hasEvent = dayEvents.length > 0;

    const isToday = moment(day).isSame(new Date(), "day");

    const holiday = holidays.find((holiday) =>
      moment(isIndia ? holiday.date.iso : holiday.date).isSame(day, "day")
    );

    return (
      <DayCell
        key={day}
        hasEvent={hasEvent}
        isToday={isToday}
        isOutsideMonth={isOutsideMonth}
        isExpanded={isExpanded}
        sx={{
          height: isExpanded
            ? { xs: "20vw", sm: "10.072vw", md: "6.9vw" }
            : { xs: "20vw", sm: "10.072vw", md: "6.319vw" },
          cursor: "pointer",
        }}
        onClick={() => handleNavigateToWeek(day.toDate())}
      >
        <Box
          sx={{
            backgroundColor: holiday
              ? "#DEA829"
              : hasEvent
              ? "#FFFFFF"
              : "inherit",
            padding: { xs: "8%", md: "7%" },
            height: "40%",
            display: "flex",
            alignItems: "center",
            columnGap: "0.5vw",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "2.126vw", sm: "1.679vw", md: "1.111vw" },
              color: holiday ? "#FFFFFF" : "inherit",
            }}
          >
            {moment(day).format("D")}
          </Typography>

          {holiday && (
            <Typography
              sx={{
                fontWeight: { sm: 500, md: 600 },
                fontSize: { xs: "1.518vw", sm: "1.199vw", md: "0.694vw" },
                color: "#FFFFFF",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {holiday?.name}
            </Typography>
          )}
        </Box>

        <Box sx={{ position: "relative", height: "60%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: { xs: "80%", md: "70%" },
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {expectedCheckoutsCount > 0 && (
              <Box
                sx={{
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => handleIconClick("guestinfo", day)}
              >
                <Badge
                  badgeContent={expectedCheckoutsCount}
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#DEA829",
                      color: "#FFFFFF",
                      fontSize: { xs: "1.215vw", sm: "0.959vw", md: "0.556vw" },
                      height: { xs: "2.428vw", sm: "1.918vw", md: "1.111vw" },
                      width: { xs: "2.428vw", sm: "1.918vw", md: "1.111vw" },
                      minWidth: 0,
                      padding: { xs: 0, sm: "inherit" },
                      position: "absolute",
                      top: { xs: "41%", sm: "30%" },
                      left: "15%",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "4.362vw", sm: "3.444vw", md: "1.994vw" },
                      height: { xs: "4.218vw", sm: "3.331vw", md: "1.929vw" },
                    }}
                  >
                    <img
                      src={IconConstants.CalendarCheckOut}
                      alt="Expected Checkout Icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Badge>
              </Box>
            )}
            {reservationsCount > 0 && (
              <Box
                sx={{ cursor: "pointer", position: "relative" }}
                onClick={() => handleIconClick("reservations", day)}
              >
                <Badge
                  badgeContent={reservationsCount}
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#9747FF",
                      color: "#FFFFFF",
                      fontSize: { xs: "1.215vw", sm: "0.959vw", md: "0.556vw" },
                      height: { xs: "2.428vw", sm: "1.918vw", md: "1.111vw" },
                      width: { xs: "2.428vw", sm: "1.918vw", md: "1.111vw" },
                      minWidth: 0,
                      padding: { xs: 0, sm: "inherit" },
                      position: "absolute",
                      top: { xs: "41%", sm: "30%" },
                      left: "10%",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "4.059vw", sm: "3.205vw", md: "1.856vw" },
                      height: { xs: "4.046vw", sm: "3.195vw", md: "1.851vw" },
                    }}
                  >
                    <img
                      src={IconConstants.CalendarReserve}
                      alt="Reservations Icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Badge>
              </Box>
            )}
          </Box>
        </Box>
      </DayCell>
    );
  };

  return (
    <WrapperBox
      // className="custom-scrollbar"
      sx={{ minWidth: { xs: "700px", sm: 1 } }}
    >
      <StyledGrid container>
        <HeaderRow container>
          {daysOfWeek.map((day) => (
            <Grid
              item
              //xs={(isExpanded ? 12 : 11.86) / 7}
              xs={12 / 7}
              key={day}
              sx={{ textAlign: "center" }}
            >
              <Box
                sx={{
                  padding: { xs: "10% 0", sm: "10.2% 0", md: "4.6% 0" },
                  border: "0.8px solid #FCF0D4",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: "#616161",
                    fontSize: { xs: "2.428vw", sm: "1.918vw", md: "1.111vw" },
                  }}
                >
                  {/* {day.slice(0, 3)} */}
                  {isEnglish
                    ? t(`days.${day.toLowerCase()}`).slice(0, 3)
                    : t(`days.${day.toLowerCase()}`)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </HeaderRow>

        <Grid
          container
          sx={{
            overflowY: "auto",
            maxHeight: isExpanded
              ? { xs: 1, sm: "50vh", md: "70vh" }
              : { xs: "30.5vh", sm: "35.8vh", md: "50vh" },
          }}
          //   className="custom-scrollbar"
        >
          {Array.from({ length: weeksInMonth }).map((_, weekIndex) => (
            <Grid container key={weekIndex} item xs={12}>
              {Array.from({ length: 7 }).map((_, dayIndex) => {
                const day = moment(selectedDate)
                  .startOf("month")
                  .add(weekIndex * 7 + dayIndex - startOfMonth.day(), "days");

                return (
                  <Grid item xs={12 / 7} key={dayIndex}>
                    {renderDayCell(
                      day,
                      day.month() !== moment(selectedDate).month()
                    )}
                  </Grid>
                );
              })}
            </Grid>
          ))}
        </Grid>
      </StyledGrid>
    </WrapperBox>
  );
};

export default MonthView;
