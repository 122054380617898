import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ApiClient from "../../apis/Api-client";
import { END_POINT } from "../../utils/End-points";
import { successToster } from "../../shared/toster/Toster";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

const CancelReservationModal = ({
  bookingData,
  handleClosePopover = () => {},
  reset = () => {},
  handleCloseModal = () => {},
}) => {
  const { t } = useTranslation();
  const { api } = ApiClient();
  const themeStyles = useTheme();
  let primaryColor = themeStyles.palette.orange_theme.primary.main;
  let contrastText = themeStyles.palette.orange_theme.primary.contrastText;

  const [cancelReasons, setCancelReasons] = useState([]);
  const [status_reason_id, setStatus_reason_id] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { reservation_id, booking_id, name, room_id, isAll } = bookingData;

  useEffect(() => {
    getCancelReasons();
  }, []);

  const getCancelReasons = async () => {
    try {
      const cancelReason = await api.get(
        END_POINT.STATUS.GET_ALL_CANCELLATION_REASON
      );
      setCancelReasons(cancelReason?.data?.data || []);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCancelReservation = async () => {
    try {
      setIsSubmitted(true);
      const response = await api.post(
        END_POINT.RESERVATION.CANCEL_RESERVATION,
        {
          reservation_id: !!reservation_id ? reservation_id : null,
          booking_id,
          status_reason_id,
        }
      );
      if (response.status == 200) {
        handleClosePopover();
        successToster(
          <span
            dangerouslySetInnerHTML={{
              __html: t("pages.booking_form.toast.reservation_cancelled", {
                name: bookingData?.name,
              }),
            }}
          />
        );
        reset();
      }
    } catch (error) {
      console.error("Error while Canceling:", error);
    } finally {
      setTimeout(() => {
        setIsSubmitted(false);
      }, 2000);
    }
  };

  return (
    <Box
      sx={{
        width: { xs: "85.051vw", sm: "50.564vw", md: "26.389vw" },
        margin: "0 auto",
      }}
    >
      <Typography
        sx={{
          fontSize: { md: "1.12vw" },
          fontWeight: 500,
          color: "#252525",
          ml: { xs: "12px", sm: 0 },
          marginTop: { xs: "5vw", sm: 0 },
        }}
      >
        {t(
          "common_components.cancel_reservation.reason_for_reservation_cancel"
        )}
      </Typography>
      <FormControl size="small" fullWidth sx={{ margin: "7% 0 10% 0" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "none" },
            alignItems: { xs: "center", sm: "none" },
            width: "100%",
          }}
        >
          <InputLabel
            sx={{
              marginLeft: { xs: "2.364vw", sm: "auto" },
              fontSize: {
                xs: "3.26vw",
                sm: "2vw",
                md: "1vw",
              },
            }}
          >
            {t("common_components.cancel_reservation.select_reason")}
          </InputLabel>
          <Select
            fullWidth
            value={status_reason_id}
            label={t("common_components.cancel_reservation.select_reason")}
            onChange={(e) => setStatus_reason_id(e?.target?.value)}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: { xs: "60vw", sm: "50vw", md: "20vw" },
                  width: { xs: "80.051vw", sm: "45.564vw", md: "26.389vw" },
                },
              },
            }}
            sx={{
              border: "8px",
              width: { xs: "95%", sm: "100%" },
              "& .MuiSelect-select": {
                fontSize: { md: "1.11vw" },
              },
              "& .MuiMenuItem-root": {
                fontSize: { md: "1.11vw" },
              },
            }}
            slotProps={{
              input: {
                fontSize: { md: "0.12vw" },
              },
            }}
          >
            {cancelReasons?.map((reason) => (
              <MenuItem
                value={reason.status_reason_id}
                sx={{
                  fontSize: {
                    xs: "3.26vw",
                    sm: "2vw",
                    md: "1vw",
                    color: "#606060",
                  },
                }}
              >
                {t(
                  `common_components.cancel_reservation.reasons.${reason.status_reason
                    .toLowerCase()
                    .replace(/\s+/g, "_")}`
                )}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-around", sm: "space-between" },
          alignItems: "center",
          margin: "0 auto",
          gap: { xs: "10px", sm: 0 },
          marginBottom: { xs: "7vw", sm: "3vw", md: 0 },
        }}
      >
        <Button
          onClick={handleCloseModal}
          variant="outlined"
          sx={{
            width: { xs: "43%", sm: "48%" },
            color: primaryColor,
            border: `1px solid ${primaryColor}`,
            fontWeight: 700,
          }}
        >
          {t(`common_components.cancel_reservation.cancel`)}
        </Button>
        <Button
          variant="contained"
          disabled={!status_reason_id || isSubmitted}
          onClick={handleCancelReservation}
          sx={{
            width: { xs: "43%", sm: "48%" },
            color: contrastText,
            backgroundColor: primaryColor,
            fontWeight: 700,
          }}
        >
          {t(`common_components.cancel_reservation.confirm`)}
        </Button>
      </Box>
    </Box>
  );
};

export default CancelReservationModal;
