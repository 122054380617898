import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

// URL for fetching translations (assume the language key is appended dynamically)
//const s3BaseURL = 'https://localization-poc.s3.ap-south-1.amazonaws.com/localization/';

// const loadLanguage = async (lang) => {
//     try {
//         const response = await fetch(`${s3BaseURL}${lang}.json`);
//         if (response.ok) {
//             console.log("🚀 ~ loadLanguage ~ response:", response)
//             return await response.json();
//         } else {
//             console.error(`Could not load translations for language: ${lang}`);
//             return {};
//         }
//     } catch (error) {
//         console.error(`Failed to fetch translations: ${error}`);
//         return {};
//     }
// };

console.log("Initializing i18next...");
i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: localStorage.getItem("language") || "en",
    load: "languageOnly", // Only load the language file
    backend: {
      // loadPath: "./localization/{{lng}}.json", // Make sure this is correct/
      loadPath: process.env.REACT_APP_LOCALIZATION_URL, // Make sure this is correct
    },
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  })
  .then(() => {
    console.log("i18next initialized");
  })
  .catch((err) => {
    console.error("i18next initialization error:", err);
  });

export default i18n;
