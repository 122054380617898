import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import ApiClient from "../../../apis/Api-client";
import { END_POINT } from "../../../utils/End-points";
import "./Login-component.scss";
import { errorToster, successToster } from "../../../shared/toster/Toster";
import { PersonRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import IconConstants from "../../../utils/IconConstants";
import { Helper } from "../../../utils/Helpers";
import { useTranslation } from "react-i18next";
import LoginFormConfig from "../../../config/forms/LoginForm-config";
import loginBg from "../../../assets/images/login_bg_mob.png";
const { getPayloadFromToken } = new Helper();

const LoginForm = () => {
  const navigate = useNavigate();
  const { api } = ApiClient();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginFormConfig(t)),
  });

  const onSubmitHandler = async (finalData) => {
    try {
      let loginRes = await api.post(
        END_POINT.AUTH.LOGIN,
        finalData,
        "Unable To Login User!"
      );
      if (loginRes?.status === 200) {
        api.setRequestHeader(
          "Authorization",
          `Bearer ${loginRes?.data?.data?.accessToken}`
        );
        const tokenPayload = getPayloadFromToken(
          loginRes?.data?.data?.accessToken
        );
        sessionStorage.setItem("user_key", tokenPayload.user.user_key);
        navigate("/");
        setTimeout(() => {
          successToster(
            <span
              dangerouslySetInnerHTML={{
                __html: t("pages.login_page.toast.login_success", {
                  name: loginRes?.data?.data?.name,
                }),
              }}
            />
          );
        }, 600);
      }
    } catch (error) {
      if (error?.response?.status == 422) {
        errorToster(t("pages.login_page.alerts.invalid_username_or_password"));
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      className="mainBox"
      sx={{
        backgroundImage: { xs: `url(${loginBg})`, sm: "none" },
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: { sm: "#F7F8F8" },
      }}
    >
      <Box
        className="flexBox"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          margin: "0 auto",
          width: { xs: "91.795%", sm: "80.5%", md: "45.85%" },
          height: { xs: "auto", sm: "64.78vw", md: "37.5vw" },
          border: { xs: "1px solid #D6A01E", sm: "0.1px solid #A7A7A7" },
          boxShadow: { sm: "0px 12px 10px 1px #00000040" },
          backgroundColor: "#FFFFFF",
          p: { xs: "5% 0 10% 0", sm: 0 },
        }}
      >
        <Box
          className="flexChild"
          sx={{ display: { xs: "none", sm: "initial" } }}
        ></Box>
        <Box className="flexChild2" sx={{ width: { xs: 1, sm: "45%" } }}>
          <Box
            sx={{
              width: { xs: "87%", sm: "88%" },
              margin: { xs: "0 auto", sm: 0 },
            }}
          >
            <Box
              sx={{
                width: { xs: "14.633vw", sm: "9.592vw", md: "5.556vw" },
                height: { xs: "14.146vw", sm: "9.272vw", md: "5.37vw" },
                margin: { xs: "0 auto 7% auto", sm: "0 auto 8% auto" },
              }}
            >
              <img
                src={IconConstants.GuestHivesLogo}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  boxShadow: "0px 5px 12px rgba(0, 0, 0, 0.25)",
                }}
              />
            </Box>
            <Box
              sx={{
                padding: { sm: "5% 0" },
                marginBottom: { xs: "10%", sm: "5%" },
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              <Typography
                id="welcomeTypo"
                sx={{
                  fontSize: { xs: "4.615vw", sm: "2.398vw", md: "1.39vw" },
                  marginBottom: { xs: "5.5%", sm: "5%" },
                }}
              >
                {t("pages.login_page.welcome_to_guest_hives")}
              </Typography>
              <Typography
                id="subTypo"
                sx={{
                  fontSize: { xs: "4.103vw", sm: "1.918vw", md: "1.111vw" },
                }}
              >
                {t("pages.login_page.login_to_your_account")}
              </Typography>
            </Box>

            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "5.128vw", sm: "2.88vw ", md: "1.67vw" },
                }}
              >
                <TextField
                  type="text"
                  name="login_name"
                  placeholder={t("pages.login_page.username")}
                  size="small"
                  fullWidth
                  error={!!errors.login_name}
                  helperText={errors.login_name?.message}
                  onInput={(e) => {
                    let { value } = e.target;
                    e.target.value = value.replace(/^\s+/, "");
                  }}
                  inputProps={{ maxLength: 20 }}
                  FormHelperTextProps={{
                    sx: {
                      fontSize: { xs: "3.08vw", sm: "1.333vw", md: "0.833vw" },
                    },
                  }}
                  InputProps={{
                    sx: {
                      fontSize: { xs: "3.59vw", sm: "1.68vw", md: "0.97vw" },
                      height: {xs:"12.308vw", sm:"auto"}
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <PersonRounded
                            fontSize="small"
                            sx={{ color: "#c8c8c8" }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: { xs: "3.59vw", sm: "1.68vw", md: "0.97vw" },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: { xs: "#E7AC20", sm: "#A7A7A7" },
                    },
                  }}
                  {...register("login_name")}
                />

                <TextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("pages.login_page.password")}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  fullWidth
                  size="small"
                  FormHelperTextProps={{
                    sx: {
                      fontSize: { xs: "3.08vw", sm: "1.333vw", md: "0.833vw" },
                    },
                  }}
                  onInput={(e) => {
                    let { value } = e.target;
                    e.target.value = value.replace(/^\s+/, "");
                  }}
                  inputProps={{ maxLength: 12 }}
                  InputLabelProps={{
                    sx: {
                      fontSize: { xs: "3.59vw", sm: "1.68vw", md: "0.97vw" },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: { xs: "#E7AC20", sm: "#A7A7A7" },
                    },
                  }}
                  {...register("password")}
                  InputProps={{
                    sx: {
                      fontSize: { xs: "3.59vw", sm: "1.68vw", md: "0.97vw" },
                      height: {xs:"12.308vw", sm:"auto"}
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility
                              fontSize="small"
                              sx={{ color: "#c8c8c8" }}
                            />
                          ) : (
                            <VisibilityOff
                              fontSize="small"
                              sx={{ color: "#c8c8c8" }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  id="loginButton"
                  sx={{
                    fontSize: { xs: "4.103vw", sm: "1.918vw", md: "1.12vw" },
                    fontWeight: "600",
                    height: { xs: "12.308vw", sm: "auto" },
                    color: "#FFFFFF",
                    background:
                      "linear-gradient(180deg, #E7AC20 0%, rgba(0, 0, 0, 0) 809.09%)",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#E98D12",
                      fontWeight: 600,
                    },
                    marginTop: {xs:"9%", sm:"6%"},
                    borderRadius: "4px",
                  }}
                >
                  {t("pages.login_page.login")}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
