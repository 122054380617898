import { createSlice } from "@reduxjs/toolkit";

const dashBoardSlice = createSlice({
  name: "dashBoard",
  initialState: {
    dashBoardData: null,
    dashBoardReservation: null,
    dashBoardCheckin: null,
  },
  reducers: {
    setDashBoardData: (state, action) => {
      state.dashBoardData = action.payload;
    },
    setDashBoardReservation: (state, action) => {
      state.dashBoardReservation = action.payload;
    },
    setDashBoardCheckin: (state, action) => {
      state.dashBoardCheckin = action.payload;
    },
    removeDashBoardData: (state, action) => {
      state.dashBoardData = null;
    },
  },
});

export const {
  setDashBoardData,
  setDashBoardReservation,
  setDashBoardCheckin,
  removeDashBoardData,
} = dashBoardSlice.actions;
export const selectDashBoardData = (state) => state.dashBoard.dashBoardData;
export const selectDashboardReservation = (state) =>
  state.dashBoard.dashBoardReservation;
export const selectDashboardCheckin = (state) =>
  state.dashBoard.dashBoardCheckin;

export default dashBoardSlice.reducer;
