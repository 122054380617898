import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MonthView from "./MonthView.jsx";
import WeekView from "./WeekView.jsx";
import { useState } from "react";
import { format, addDays } from "date-fns";
import {
  calendarViews,
  dashboardViewTypes,
} from "../../pages/dashboard/ReceptionDashboard";
// import { ConfigProvider, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import IconConstants from "../../utils/IconConstants";
// import "antd/dist/reset.css";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// const { MonthPicker } = DatePicker;

const Calendar = ({
  roomTypeData,
  setIsExpand,
  isExpanded,
  viewType,
  setViewType,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isEnglish = currentLanguage === "en";
  const xs = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const sm = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const md = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [view, setView] = useState(() => {
    return localStorage.getItem("calenderView") || "Month";
  });

  const [startFrom, setStartFrom] = useState(() => {
    const savedDate = localStorage.getItem("startFrom");
    return savedDate ? new Date(savedDate) : new Date();
  });
  const isMonthView = view === "Month";
  const endDate = addDays(startFrom, 6);
  const [month, setMonth] = useState(() => {
    const savedMonth = localStorage.getItem("month");
    return savedMonth ? dayjs(savedMonth) : dayjs();
  });

  const handleMonthChanges = (date, dateString) => {
    const newDate = new Date(date);
    setStartFrom(newDate);
    setMonth(date);
    localStorage.setItem("startFrom", newDate.toISOString());
    localStorage.setItem("month", date);
  };

  const handlePreviousWeek = () => {
    setStartFrom((prev) => {
      const newDate = addDays(prev, -7);
      setMonth(dayjs(newDate));
      localStorage.setItem("startFrom", newDate.toISOString());
      localStorage.setItem("month", dayjs(newDate));
      return newDate;
    });
  };

  const handleNextWeek = () => {
    setStartFrom((prev) => {
      const newDate = addDays(prev, 7);
      setMonth(dayjs(newDate));
      localStorage.setItem("startFrom", newDate.toISOString());
      localStorage.setItem("month", dayjs(newDate));
      return newDate;
    });
  };

  const handleViewChange = (newView) => {
    setView(newView);
    localStorage.setItem("calenderView", newView);
  };

  return (
    <>
      <Grid
        container
        sx={{
          mb: { xs: "2%", sm: "2%", md: "1.5%" },
          justifyContent: { sm: isExpanded ? "space-between" : "flex-start" },
          alignItems: "center",
          gap: { xs: "2.5vw", sm: "1.918vw", md: "1.389vw" },
        }}
      >
        <Grid
          item
          xs={3.4}
          sm={1.4}
          md={isExpanded ? 1.08 : 1.75}
          order={{ xs: 1 }}
        >
          <TextField
            select
            name="calendarView"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                height: { xs: "7.692vw", sm: "3.597vw", md: "2.15vw" },
                fontSize: { xs: "3.077vw", sm: "1.44vw", md: "0.833vw" },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
              "& .MuiSelect-icon": {
                color: "#F6C160",
              },
              fontWeight: 500,
              color: "#25213B",
              backgroundColor: "#FFFBF1",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FFDC8A !important",
              },
            }}
            value={view}
            onChange={(e) => handleViewChange(e.target?.value)}
          >
            {calendarViews?.map((option, opInd) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  fontSize: { xs: "3vw", sm: "1.4vw", md: "0.8vw" },
                  borderBottom: opInd == 0 && "1px solid #FFEABA",
                  minHeight: 0,
                }}
              >
                {t(
                  `pages.home_page.room_view_section.${option.label
                    .toLowerCase()
                    .replace(/\s+/g, "_")}`
                )}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {!isExpanded && (
          <Grid item xs={3.68} sm={1.74} order={{ xs: 1 }}>
            <Box
              sx={{
                display: { xs: "flex" },
                border: "0.5px solid #FFDC8A",
                borderRadius: "4px",
                height: { xs: "7.692vw", sm: "3.597vw", md: "2.084vw" },
              }}
            >
              {dashboardViewTypes.map((option, index) => (
                <Box
                  key={option.value}
                  onClick={() => {
                    setViewType(option.value);
                    localStorage.setItem("viewType", option.value);
                  }}
                  sx={{
                    width: "50%",
                    backgroundColor:
                      viewType === option.value ? "#DEA829" : "#F9F1DD",
                    borderRadius: index === 0 ? "3px 0 0 3px" : "0 3px 3px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.3s ease",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      height: { xs: "5.128vw", sm: "2.398vw", md: "1.389vw" },
                      width: { xs: "5.128vw", sm: "2.398vw", md: "1.389vw" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        viewType === option.value
                          ? option.activeIcon
                          : option.icon
                      }
                      alt={option.value}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        )}

        {isMonthView && (
          <Grid
            item
            xs={4}
            sm={1.9}
            md={isExpanded ? 1.25 : 2.1}
            order={{ xs: isExpanded ? 2 : 4, sm: 3 }}
          >
            {/* <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#DEA829",
                  fontSize: 12,
                },
              }}
            >
              <MonthPicker
                className="custom-month-picker"
                popupStyle={{ zIndex: 9999 }}
                format="MMM YYYY"
                value={month}
                allowClear={false}
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#FFFBF1",
                  color: "#25213B",
                  borderColor: "#FFDC8A",
                  height: xs
                    ? "7.692vw"
                    : md
                    ? "2.083vw"
                    : sm
                    ? "3.597vw"
                    : "auto",
                }}
                onChange={handleMonthChanges}
                popupClassName="custom-month-popup"
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </ConfigProvider> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month"]}
                value={month}
                format="MMM YYYY"
                openTo="month"
                onChange={handleMonthChanges}
                slotProps={{
                  desktopPaper: {
                    sx: {
                      maxHeight: { md: "21vw" },
                      overflowY: "auto",
                      borderRadius: "15px",
                      "& .MuiPickersYear-yearButton, & .MuiPickersMonth-monthButton":
                        {
                          fontSize: { md: "0.972vw" },
                          "&.Mui-selected": {
                            backgroundColor: "#DEA829 !important",
                            color: "#FFFFFF",
                            fontWeight: 500,
                          },
                        },
                    },
                  },
                  mobilePaper: {
                    sx: {
                      borderRadius: "15px",
                    },
                  },
                  field: {
                    readOnly: true,
                  },
                  textField: {
                    size: "small",
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        height: { xs: "7.692vw", sm: "3.597vw", md: "2.083vw" },
                        fontSize: {
                          xs: "3.077vw",
                          sm: "1.44vw",
                          md: "0.833vw",
                        },
                        borderRadius: "4px",
                        backgroundColor: "#FFFBF1",
                        color: "#25213B",
                        borderColor: "#FFDC8A !important",
                        "& fieldset": {
                          borderColor: "#FFDC8A",
                        },
                        "&:hover fieldset": {
                          borderColor: "#FFDC8A  !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#DEA829 !important",
                          borderWidth: "1px",
                          borderColor: "#FFDC8A  !important",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: {
                          xs: "4.103vw",
                          sm: "1.92vw",
                          md: "1.111vw",
                        },
                        display: "block !important",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}
        {!isMonthView && (
          <Grid
            item
            xs={12}
            sm={5.36}
            md={isExpanded ? 3.6 : 5.7}
            order={{ xs: 4, sm: 3 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: { xs: "7.692vw", sm: "3.597vw", md: "2.083vw" },
                margin: "0 auto",
              }}
            >
              <Box sx={{ width: "9%", height: 1 }}>
                <Box
                  onClick={handlePreviousWeek}
                  sx={{
                    width: { xs: "7.692vw", sm: "3.597vw", md: "2.083vw" },
                    height: 1,
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={IconConstants.CalendarLeft}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  border: "1px solid #FFDC8A",
                  backgroundColor: "#FFFBF1",
                  borderRadius: "4px",
                  height: { xs: "7.692vw", sm: "3.597vw", md: "2.083vw" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "80%",
                }}
              >
                <Typography
                  sx={{
                    color: "#25213B",
                    fontSize: { xs: "3.077vw", sm: "1.439vw", md: "0.833vw" },
                    fontWeight: 500,
                    textAlign: "center",
                    textWrap: "nowrap",
                  }}
                >
                  {`${format(startFrom, "dd-MMM-yyyy")} - ${format(
                    endDate,
                    "dd-MMM-yyyy"
                  )}`}
                </Typography>
              </Box>

              <Box sx={{ width: "9%", height: 1 }}>
                <Box
                  onClick={handleNextWeek}
                  sx={{
                    width: { xs: "7.692vw", sm: "3.597vw", md: "2.083vw" },
                    height: 1,
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={IconConstants.CalendarRight}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        )}

        <Grid
          item
          xs={1.1}
          sm={isExpanded ? 1.08 : 1.45}
          sx={{
            ml: "auto",
          }}
          order={{ xs: 3, sm: 4 }}
        >
          <Box>
            <Tooltip title={t("pages.home_page.room_view_section.collapse")}>
              <Box
                sx={{
                  height: { xs: "7.692vw", sm: "3.597vw", md: "2.08vw" },
                  width: { xs: "7.692vw", sm: "3.597vw", md: "2.08vw" },
                  cursor: "pointer",
                  ml: "auto",
                }}
                onClick={() => {
                  setIsExpand((prev) => {
                    const newValue = !prev;
                    localStorage.setItem(
                      "isCalendarExpand",
                      JSON.stringify(newValue)
                    );
                    return newValue;
                  });
                }}
              >
                <img
                  src={
                    isExpanded ? IconConstants.Shrink : IconConstants.ExpandIcon
                  }
                  style={{
                    height: "100%",
                    width: "100%",
                    transition: "all 0.3s ease",
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      {view === "Month" ? (
        <Box
          sx={{
            overflowX: "auto",
            height: { xs: isExpanded ? "80vh" : "auto", sm: "auto" },
          }}
        >
          <MonthView
            selectedDate={startFrom}
            isExpanded={isExpanded}
            setView={setView}
            setStartFrom={setStartFrom}
            setMonth={setMonth}
            isEnglish={isEnglish}
          />
        </Box>
      ) : (
        <WeekView
          roomTypeData={roomTypeData}
          weekStart={startFrom}
          weekEnd={endDate}
          isExpanded={isExpanded}
          isEnglish={isEnglish}
        />
      )}
    </>
  );
};

export default Calendar;
