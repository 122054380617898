import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconConstants from "../../utils/IconConstants";
import "./Toaster.scss";
const toster_config = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const successToster = (msg) => {
  const isMobile = window.innerWidth <= 700;
  return toast.success(msg, {
    ...toster_config,
    style: {
      width: "max-content",
      color: isMobile ? "#252525" : "#000000",
      backgroundColor: "#DFFFE5",
      borderLeft: "20px solid #62BA60",
      borderRadius: "12px",
      fontWeight: 500,
    },
    icon: ({ theme, type }) => (
      <img
        src={IconConstants.SuccessToaster}
        alt=""
        style={{
          width: isMobile ? "10.256vw" : "40px",
          height: isMobile ? "10.256vw" : "40px",
        }}
      />
    ),
  });
};

export const errorToster = (msg) => {
  const isMobile = window.innerWidth <= 700;
  return toast.error(msg, {
    ...toster_config,
    style: {
      color: isMobile ? "#252525" : "#000000",
      backgroundColor: "#FFCBC2",
      borderLeft: "20px solid #ED705B",
      borderRadius: "12px",
      fontWeight: 500,
    },
    icon: ({ theme, type }) => (
      <img
        src={IconConstants.ToastErrorIcon}
        alt=""
        style={{
          width: isMobile ? "7.692vw" : "25px",
          height: isMobile ? "7.692vw" : "25px",
          marginRight: isMobile ? "" : "10px",
          marginTop: isMobile ? "5px" : "",
        }}
      />
    ),
  });
};

export const deleteToster = (msg) => {
  const isMobile = window.innerWidth <= 700;
  return toast.error(msg, {
    ...toster_config,
    style: {
      color: isMobile ? "#252525" : "#000000",
      width: "max-content",
      backgroundColor: "#FFCBC2",
      borderLeft: "20px solid #ED705B",
      borderRadius: "12px",
      fontWeight: 500,
    },
    icon: ({ theme, type }) => (
      <img
        src={IconConstants.ToastDeleteIcon}
        alt=""
        style={{
          width: isMobile ? "10.256vw" : "40px",
          height: isMobile ? "10.256vw" : "40px",
        }}
      />
    ),
  });
};

export const warnToster = (msg) => {
  const isMobile = window.innerWidth <= 700;
  return toast.warn(msg, {
    ...toster_config,
    style: {
      color: isMobile ? "#252525" : "#000000",
      backgroundColor: "#F2E1B8",
      borderLeft: "20px solid #D9A62E",
      borderRadius: "12px",
      fontWeight: 500,
      width: "fit-content",
    },
    icon: ({ theme, type }) => (
      <img
        src={IconConstants.ToastWarnIcon}
        alt=""
        style={{
          width: isMobile ? "10.256vw" : "25px",
          height: isMobile ? "10.256vw" : "25px",
        }}
      />
    ),
  });
};

export const infoToster = (msg) => {
  return toast.info(msg, toster_config);
};

export const Toster = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
      theme="colored"
      style={{ width: "max-content" }}
    />
  );
};
