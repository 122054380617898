import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import IconConstants from "../../../utils/IconConstants";
import IndividualRoomContainer from "../../../components/dashboard/IndividualRoomContainer";
import ArrivalActivityTable from "../../../components/dashboard/ArrivalActivityTable";
import DepartureActivityTable from "../../../components/dashboard/DepartureActivityTable";
import PaymentsDueActivityTable from "../../../components/dashboard/PaymentsDueActivityTable";
import ApiClient from "../../../apis/Api-client";
import { END_POINT } from "../../../utils/End-points";
import { useDispatch, useSelector } from "react-redux";
import { setBookingStatusData } from "../../../core/store/redux/slices/BookingStatus-slice";
import { setRoomTypeData } from "../../../core/store/redux/slices/RoomType-slice";
import { setReservationData } from "../../../core/store/redux/slices/Reservation-slice";
import { setCheckinData } from "../../../core/store/redux/slices/Checkin-slice";
import { selectTriggered } from "../../../core/store/redux/slices/DashboardApi-slice";
import { warnToster } from "../../../shared/toster/Toster";
import {
  helper,
  ScrollContainer,
  ScrollItem,
  activityTabsData,
  dashboardViewTypes,
} from ".";
import { useMediaQuery } from "@mui/material";
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import { format } from "date-fns";
import {
  CLEANING_STATUSES,
  RESERVATION_STATUSES,
  OCCUPANCY_STATUSES,
} from "../../../utils/Constants";
import Calendar from "../../../components/calendar/Calendar.jsx";
const { CONFIRMED, TENTATIVE } = RESERVATION_STATUSES;
const { CHECK_IN, CHECK_OUT, ROOM_SHIFT } = OCCUPANCY_STATUSES;
const { ASSIGNED, CLEAN, DIRTY } = CLEANING_STATUSES;

export const ReceptionDashboard = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [viewType, setViewType] = useState(() => {
    return localStorage.getItem("viewType") || "Room View";
  });
  const [roomsFlag, setRoomsFlag] = useState("All");
  const [activityFlag, setActivityFlag] = useState("All");
  const [modalLabel, setModalLabel] = useState("");
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [todayCheckin, setTodayCheckin] = useState({ data: [], length: 0 });
  const [todayCheckout, setTodayCheckOut] = useState({ data: [], length: 0 });
  const [unCleanedRooms, setUnCleanedRooms] = useState([]);
  const [underCleaningRooms, setUnderCleaningRooms] = useState([]);
  const [cleanedRooms, setCleanedRooms] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [mobTab, setMobTab] = useState(0);
  const [expectedArrival, setExpectedArrival] = useState({
    data: [],
    length: 0,
  });
  const [expectedDeparture, setExpectedDeparture] = useState({
    data: [],
    length: 0,
  });
  const [arrivalTableData, setArrivalTableData] = useState([]);
  const [departureTableData, setDepartureTableData] = useState([]);
  const [floors, setFloors] = useState([]);
  const [totalOccupancyData, setTotalOccupancyData] = useState({
    totalRooms: 0,
    totalCheckin: [],
    totalCheckOut: [],
    totalReserved: [],
    todaysReserved: [],
    underMaintenance: [],
  });
  const [selectionArray, setSelectionArray] = useState([
    { isAllSelected: true },
  ]);
  const [currentRoomTypeArray, setCurrentRoomTypeArray] = useState([]);
  const [currentFloorTypeArray, setCurrentFloorTypeArray] = useState([]);
  const [pendingDue, setPendingDue] = useState([]);
  const [totalRooms, setTotalRooms] = useState(0);
  const [unCleanedStatusId, setUnCleanedStatusId] = useState();
  const [underCleaningStatusId, setUnderCleaningStatusId] = useState();
  const [cleanedStatusId, setCleanedStatusId] = useState();
  const [houseKeepStatus, setHouseKeepStatus] = useState();
  const [todaysFilter, setTodaysFilter] = useState();
  const [expanded, setExpanded] = useState(false);
  const [isCalendarExpand, setIsCalendarExpand] = useState(() => {
    const savedValue = localStorage.getItem("isCalendarExpand");
    return savedValue ? JSON.parse(savedValue) : false;
  });
  const isCalendarView = viewType === "Calendar View";

  const dispatch = useDispatch();
  const { api } = ApiClient();
  const { date } = helper.getDate();
  const { t } = useTranslation();

  const houseCleaningData = [
    {
      name: "Clean",
      value: cleanedRooms.length,
      icon: IconConstants.CleanedGold,
      activeIcon: IconConstants.CleanedWhite,
      statusId: cleanedStatusId,
      houseKeepingData: cleanedRooms,
    },
    {
      name: "Assigned",
      value: underCleaningRooms.length,
      icon: IconConstants.CleaningGold,
      activeIcon: IconConstants.CleaningWhite,
      statusId: underCleaningStatusId,
      houseKeepingData: underCleaningRooms,
    },
    {
      name: "Dirty",
      value: unCleanedRooms.length,
      icon: IconConstants.UncleanedGold,
      activeIcon: IconConstants.UncleanedWhite,
      statusId: unCleanedStatusId,
      houseKeepingData: unCleanedRooms,
    },
  ];

  const roomOccupancyData = {
    otherOptions: [
      {
        name: "Vacant",
        value: totalOccupancyData.totalCheckOut?.length,
        icon: IconConstants.VacantIcon,
        activeIcon: IconConstants.VacantWhite,
        color: roomsFlag == "Vacant" ? "#FFFFFF" : "#62BA60",
        occupancyData: totalOccupancyData.totalCheckOut,
        backgroundColor: roomsFlag == "Vacant" ? "#62BA60" : "#D9FFD8",
        borderColor: "#98F696",
      },
      {
        name: "Blocked",
        value: totalOccupancyData.underMaintenance?.length,
        icon: IconConstants.BlockedIcon,
        activeIcon: IconConstants.BlockedIconWhite,
        color: "#252525",
        occupancyData: totalOccupancyData.underMaintenance,
        backgroundColor: roomsFlag == "Blocked" ? "#CECECE" : "#F0F0F0",
        borderColor: "#D4D4D4",
      },
    ],
    allocatedOptions: {
      name: "Allocated",
      value:
        totalOccupancyData.totalCheckin?.length +
        totalOccupancyData.todaysReserved?.length,
      icon: IconConstants.AllocatedViolet,
      activeIcon: IconConstants.AllocatedWhite,
      color: roomsFlag == "Allocated" ? "#FFFFFF" : "#637A9F",
      backgroundColor: roomsFlag == "Allocated" ? "#7696CA" : "#F0F6FF",
      borderColor: "#B4D1FF",
      occupancyData: [
        ...totalOccupancyData.totalCheckin,
        ...totalOccupancyData.todaysReserved,
      ],
      subOptions: [
        {
          name: "Occupied",
          value: totalOccupancyData.totalCheckin?.length,
          color: "#ED705B",
          backgroundColor: roomsFlag == "Occupied" ? "#ED705B" : "#FFEFEC",
          occupancyData: totalOccupancyData.totalCheckin,
          borderColor: "#FFCAC1",
        },
        {
          name: "Reserved",
          value: totalOccupancyData.todaysReserved?.length,
          color: "#9747FF",
          backgroundColor: roomsFlag == "Reserved" ? "#9F70FD" : "#F3EAFF",
          occupancyData: totalOccupancyData.todaysReserved,
          borderColor: "#D9BCFF",
        },
      ],
    },
  };

  const todaysArrivalAndDeparture = [
    {
      name: "Arrival",
      label: t("pages.home_page.todays_arrival_and_departure.arrival"),
      icon: IconConstants.Arrival,
      activeIcon: IconConstants.ArrivalWhite,
      value: todayCheckin.length + expectedArrival?.length,
      todaysData: [...expectedArrival.data, ...todayCheckin.data],
      subCounts: [
        {
          name: "Arrived",
          label: t("pages.home_page.todays_arrival_and_departure.arrived"),
          count: todayCheckin?.length,
          todaysData: todayCheckin.data,
        },
        {
          name: "Exp.Arrival",
          label: t("pages.home_page.todays_arrival_and_departure.expected"),
          count: expectedArrival.length,
          todaysData: expectedArrival.data,
        },
      ],
    },
    {
      name: "Departure",
      label: t("pages.home_page.todays_arrival_and_departure.departure"),
      icon: IconConstants.Departure,
      activeIcon: IconConstants.DepartureWhite,
      value: todayCheckout.length + expectedDeparture?.length,
      todaysData: [...expectedDeparture.data, ...todayCheckout.data],
      subCounts: [
        {
          name: "Departed",
          label: t("pages.home_page.todays_arrival_and_departure.departed"),
          count: todayCheckout?.length,
          todaysData: todayCheckout.data,
        },
        {
          name: "Exp.Departure",
          label: t("pages.home_page.todays_arrival_and_departure.expected"),
          count: expectedDeparture?.length,
          todaysData: expectedDeparture.data,
        },
      ],
    },
  ];

  const roomLabels = [
    {
      name: "Vacant",
      color: "#62BA60",
      backgroundColor: "#DFFFDE",
    },
    {
      name: "Occupied",
      color: "#ED705B",
      backgroundColor: "#FFD8D1",
    },
    {
      name: "Reserved",
      color: "#9F70FD",
      backgroundColor: "#FFF1D8",
    },
  ];

  const filters = [
    {
      label: "Vacant",
      icon: IconConstants.VacantFilter,
      activeIcon: IconConstants.VacantFilterFilled,
      totalData: totalOccupancyData.totalCheckOut,
    },
    {
      label: "Occupied",
      icon: IconConstants.OccupiedFilter,
      activeIcon: IconConstants.OccupiedFilterFilled,
      totalData: totalOccupancyData.totalCheckin,
    },
    {
      label: "Reserved",
      icon: IconConstants.ReservedFilter,
      activeIcon: IconConstants.ReservedFilterFilled,
      totalData: totalOccupancyData.todaysReserved,
    },
  ];

  const isTriggered = useSelector(selectTriggered);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setReservationData());
    dispatch(setCheckinData());
  }, []);

  useEffect(() => {
    setInitialData();
  }, [isTriggered]);

  const handleSearch = () => {
    setHouseKeepStatus(null);
    setTodaysFilter();
    if (!searchFlag) {
      setSearchFlag(true);
      if (!!searchText) {
        setRoomsFlag(searchText);
        const isRoom = roomTypes
          ?.flatMap((data) => data?.rooms)
          .find((room) => room?.room_no == searchText);
        if (!!isRoom) {
          setSelectionArray([{ isAllSelected: false }]);
        } else {
          setSelectionArray([{ isAllSelected: false }]);
          warnToster(t("pages.home_page.alert.no_results_found"));
        }
      } else {
        warnToster(t("pages.home_page.alert.enter_a_room_number_to_search"));
        setSelectionArray([{ isAllSelected: true }]);
      }
    }
  };

  useEffect(() => {
    if (!!modalLabel) {
      handleOpenModal(modalLabel);
    }
  }, [modalLabel, roomTypes]);

  const handleOpenModal = (value) => {
    if (viewType == "Room View") {
      const selectedRoomType = roomTypes?.filter(
        (roomType) => roomType.room_type_name === value
      );
      setCurrentRoomTypeArray(selectedRoomType);
    } else {
      const selectedFloorType = floors?.filter(
        (floor) => floor?.floor_code === value
      );
      setCurrentFloorTypeArray(selectedFloorType);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModalLabel("");
    setCurrentRoomTypeArray([]);
    setCurrentFloorTypeArray([]);
  };

  const setInitialData = async () => {
    try {
      let [
        todayCheckin = [],
        todayCheckout = [],
        cleaningStatus,
        roomCleaningStatus,
        roomTYpesRes = [],
        extdArrival = [],
        extdDeparture = [],
        floors = [],
        // paymentDetail,
        pendingDueData = [],
      ] = await Promise.all([
        api
          .post(
            END_POINT.BOOKING.GET_ALL_CHECKIN_CHECKOUT,
            {
              from: date.startDate(),
              to: date.endDate(),
              occupancy_status_code: CHECK_IN,
            },
            "Error Fetching Checkin"
          )
          .then((res) => res?.data?.data || [])
          .catch((error) => {
            console.error(error?.message);
          }),
        api
          .post(
            END_POINT.BOOKING.GET_ALL_CHECKIN_CHECKOUT,
            {
              from: date.startDate(),
              to: date.endDate(),
              occupancy_status_code: CHECK_OUT,
            },
            "Error Fetching Checkin"
          )
          .then((res) => res?.data?.data || [])
          .catch((error) => {
            console.error(error?.message);
          }),
        api
          .get(
            END_POINT.STATUS.GET_ALL_CLEANING_STATUS,
            null,
            "Error Fetching cleaning status"
          )
          .then((res) => res?.data?.data || [])
          .catch((error) => {
            console.error(error?.message);
          }),
        api
          .post(
            `${END_POINT.HOUSE_KEEPING.GET_ROOM_CLEANING_STATUS}/${format(
              new Date(),
              "yyyy-MM-dd"
            )}`,
            { data: {} },
            "Error Fetching room cleaning status"
          )
          .then((res) => res?.data?.data || [])
          .catch((error) => {
            console.error(error?.message);
          }),
        api
          .get(
            `${END_POINT.ROOM.GET_ALL_ROOMS_BY_ROOMTYPE}/${format(
              new Date(),
              "yyyy-MM-dd"
            )}`,
            null,
            "Error Fetching Room Types"
          )
          .then((res) => res?.data?.data || [])
          .catch((error) => {
            console.error(error?.message);
          }),
        api
          .get(
            `${END_POINT.BOOKING.GET_ALL_EXPECTED_ARRIVAL}/${format(
              new Date(),
              "yyyy-MM-dd"
            )}`,
            null,
            "Error Fetching Expected Arrival"
          )
          .then((res) => res?.data?.data || [])
          .catch((error) => {
            console.error(error?.message);
          }),
        api
          .get(
            `${END_POINT.BOOKING.GET_ALL_EXPECTED_DEPARTURE}/${format(
              new Date(),
              "yyyy-MM-dd"
            )}`,
            null,
            "Error Fetching Expected Departure"
          )
          .then((res) => res?.data?.data || [])
          .catch((error) => {
            console.error(error?.message);
          }),
        api
          .get(
            `${END_POINT.FLOOR.GET_ALL_FLOORS}/${format(
              new Date(),
              "yyyy-MM-dd"
            )}`,
            null,
            "Error Fetching floors"
          )
          .then((res) => res?.data?.data || [])
          .catch((error) => {
            console.error(error?.message);
          }),
        // api
        //   .post(
        //     END_POINT.TAX.GET_PAYMENT_DETAIL,
        //     { is_deleted: false },
        //     "Error Fetching PAyment Detail"
        //   )
        //   .then((res) => res?.data?.data || []),
        api
          .get(
            `${END_POINT.BOOKING.GET_ALL_PENDING_DUES}/${format(
              new Date(),
              "yyyy-MM-dd"
            )}`,
            null,
            "Error Fetching pending dues"
          )
          .then((res) => res?.data?.data || [])
          .catch((error) => {
            console.error(error?.message);
          }),
      ]);

      let totalRoom = 0;
      let roomTYpes = roomTYpesRes.map((roomType) => {
        const validRooms = roomType.rooms.filter(
          (room) => room.room_id !== null
        );
        return { ...roomType, rooms: validRooms };
      });
      roomTYpes.forEach((roomType) => {
        totalRoom += roomType?.rooms?.length;
      });

      setTotalRooms(totalRoom);

      const chekinBookings = {
        filteredData: todayCheckin,
        flattenedData: todayCheckin?.flatMap((item) => item.booked_rooms),
      };
      const chekoutBookings = {
        filteredData: todayCheckout,
        flattenedData: todayCheckout?.flatMap((item) => item.booked_rooms),
      };
      const flattenExtdArrival = extdArrival.flatMap(
        (arrival) => arrival.booked_rooms
      );
      const flattenExtdDeparture = extdDeparture.flatMap(
        (departure) => departure.booked_rooms
      );
      setTodayCheckin({
        data: chekinBookings.filteredData,
        length: chekinBookings.flattenedData.length,
      });
      setTodayCheckOut({
        data: chekoutBookings.filteredData,
        length: chekoutBookings.flattenedData.length,
      });
      setExpectedArrival({
        data: extdArrival,
        length: flattenExtdArrival.length,
      });
      setExpectedDeparture({
        data: extdDeparture,
        length: flattenExtdDeparture.length,
      });
      setArrivalTableData([...extdArrival, ...chekinBookings.filteredData]);
      setDepartureTableData([
        ...extdDeparture,
        ...chekoutBookings.filteredData,
      ]);

      setHouseKeepingStatus(roomCleaningStatus);
      // dispatch(setBookingStatusData(bookingStatus));
      dispatch(
        setRoomTypeData(
          roomTYpes.map((room) => ({
            room_type_id: room.room_type_id,
            room_type_name: room.room_type_name,
          }))
        )
      );
      setRoomTypes(roomTYpes);

      setPendingDue(pendingDueData);

      if (floors.length) {
        // Process the floor data
        const updatedFloorData = await Promise.all(
          floors.map(async (floor) => {
            const filteredRooms = floor.rooms.filter(
              (room) => room.room_id != null
            );
            // Filter rooms by position
            const roomStatus = await filterRoomsByPosition(filteredRooms);

            // Check and update room positions if bottom is empty
            if (roomStatus.bottom.items.length === 0) {
              updateRoomPositions(roomStatus);
            }

            // Return updated floor data
            return {
              floor_id: floor.floor_id,
              floor_name: floor.floor_name,
              floor_code: floor.floor_code,
              roomStatus: roomStatus,
            };
          })
        );

        // Set the updated floor data
        setFloors(updatedFloorData);
      }
      if (roomTYpes.length) {
        const roomOcuupancyData = setTotalRoomOccupancyByRoomType(roomTYpes);
        setTotalOccupancyData(roomOcuupancyData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function getFilteredAndFlattenBookings(data, status) {
    try {
      const filteredData = [];
      const flattenedData = [];

      for (const entry of data) {
        const filteredRooms = entry.booked_rooms.filter(
          (room) => room.occupancy_status_code === status
        );

        if (filteredRooms.length > 0) {
          filteredData.push({ ...entry, booked_rooms: filteredRooms });
          for (const room of filteredRooms) {
            flattenedData.push(room);
          }
        }
      }

      return {
        filteredData,
        flattenedData,
      };
    } catch (error) {
      console.error("error in getFilteredAndFlattenBookings", error);
    }
  }

  function setTotalRoomOccupancyByRoomType(propsArray) {
    try {
      return propsArray.reduce(
        (accumulator, property) => {
          accumulator.totalRooms += property.rooms.length;

          property.rooms.forEach((room) => {
            if (room.is_under_maintenance) {
              accumulator.underMaintenance.push(room);
            } else {
              if (room.occupancy_status_code === CHECK_IN) {
                accumulator.totalCheckin.push(room);
              }
              if (
                [CHECK_OUT, ROOM_SHIFT].includes(room.occupancy_status_code) &&
                !room.reservation_status_code
              ) {
                accumulator.totalCheckOut.push(room);
              }
              if (
                room.reservation_status_code === CONFIRMED ||
                room.reservation_status_code === TENTATIVE
              ) {
                accumulator.todaysReserved.push(room);
              }
            }
          });

          return accumulator;
        },
        {
          totalRooms: 0,
          totalCheckin: [],
          totalCheckOut: [],
          todaysReserved: [],
          underMaintenance: [],
        }
      );
    } catch (error) {
      console.error("error in setTotalRoomOccupancyByRoomType function", error);
    }
  }

  const updateRoomPositions = (roomStatus) => {
    try {
      // Initialize updatedRoomStatus
      const updatedRoomStatus = {
        top: { name: "Top", items: [] },
        bottom: { name: "Bottom", items: [] },
      };

      // Update room positions
      roomStatus.top.items.forEach((room, index) => {
        const position = index % 2 === 0 ? "top" : "bottom";
        room.position = position;
        updatedRoomStatus[position].items.push(room);
      });

      // Assign updated room positions to roomStatus
      Object.assign(roomStatus, updatedRoomStatus);
    } catch (error) {
      console.error("error in updateRoomPositions>>>", error);
    }
  };

  const filterRoomsByPosition = async (rooms) => {
    try {
      // Initialize roomStatus
      const roomStatus = {
        top: { name: "Top", items: [] },
        bottom: { name: "Bottom", items: [] },
      };

      // Filter rooms based on position
      rooms.forEach((room) => {
        const position = room?.position.toLowerCase();
        roomStatus[position]?.items?.push(room);
      });

      return roomStatus;
    } catch (error) {
      console.error("error in filterRoomsByPosition:", error);
    }
  };

  const setHouseKeepingStatus = (roomCleaningStatus) => {
    let uncleanedRooms = roomCleaningStatus.find(
      (res) => res.cleaning_status_code === DIRTY
    );

    let underCleaningdRooms = roomCleaningStatus.find(
      (res) => res.cleaning_status_code == ASSIGNED
    );

    let cleanedRooms = roomCleaningStatus.find(
      (res) => res.cleaning_status_code == CLEAN
    );

    setUnCleanedRooms(
      uncleanedRooms?.rooms.length ? uncleanedRooms?.rooms : []
    );
    setUnderCleaningRooms(
      underCleaningdRooms?.rooms.length ? underCleaningdRooms?.rooms : []
    );
    setCleanedRooms(cleanedRooms?.rooms.length ? cleanedRooms?.rooms : []);

    setCleanedStatusId(cleanedRooms?.cleaning_status_id);
    setUnderCleaningStatusId(underCleaningdRooms?.cleaning_status_id);
    setUnCleanedStatusId(uncleanedRooms?.cleaning_status_id);
  };

  const handleScroll = (scrollOffset, index) => {
    const container = document.getElementById(`scroll-container${index}`);
    container.scrollLeft += scrollOffset;
  };

  const handleResetFilters = () => {
    setHouseKeepStatus(null);
    setTodaysFilter();
    setRoomsFlag("All");
    setSearchText("");
    setSearchFlag(false);
    setSelectionArray([{ isAllSelected: true }]);
  };

  const resetActivityFilters = (table) => {
    setSelectionArray([{ isAllSelected: true }]);
    setActivityFlag("");
    setRoomsFlag("All");
    setHouseKeepStatus(null);
    setTabIndex(table);
    setSearchText("");
    setSearchFlag(false);
    setArrivalTableData([...expectedArrival.data, ...todayCheckin.data]);
    setDepartureTableData([...expectedDeparture.data, ...todayCheckout.data]);
  };

  const [bottomBar, setBottomBar] = useState(true);
  // const [prevScrollPos, setPrevScrollPos] = useState(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.pageYOffset;
  //     setBottomBar(prevScrollPos > currentScrollPos || currentScrollPos < 10);
  //     setPrevScrollPos(currentScrollPos);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [prevScrollPos]);

  // console.log("mobTab", mobTab);

  return (
    <>
      <BottomNavigation
        value={mobTab}
        onChange={(event, newValue) => {
          setMobTab(newValue);
          handleResetFilters();
        }}
        sx={{
          display: { xs: !bottomBar && "none", sm: "none" },
          bottom: "1%",
          transition: "all 0.3s ease-in-out",
          width: "85%",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "3.5%",
          zIndex: 1000,
          borderRadius: "30px",
          boxShadow: "0px 3px 8px 0px #0000003D",
          height: "15.38vw",
        }}
      >
        <BottomNavigationAction
          sx={{ borderRadius: "30px" }}
          icon={
            <Box
              sx={{
                backgroundColor: mobTab === 0 && "#FFF1CE",
                borderRadius: "50%",
                height: "11.28vw",
                width: "11.28vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={
                  mobTab === 0
                    ? IconConstants.HomeNavActive
                    : IconConstants.HomeNav
                }
                alt=""
              />
            </Box>
          }
        />
        <BottomNavigationAction
          sx={{ borderRadius: "30px" }}
          icon={
            <Box
              sx={{
                backgroundColor: mobTab === 1 && "#FFF1CE",
                borderRadius: "50%",
                height: "11.28vw",
                width: "11.28vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={
                  mobTab === 1
                    ? IconConstants.ActivityNavActive
                    : IconConstants.ActivityNav
                }
                alt=""
              />
            </Box>
          }
        />
        <BottomNavigationAction
          sx={{ borderRadius: "30px" }}
          icon={
            <Box
              sx={{
                backgroundColor: mobTab === 2 && "#FFF1CE",
                borderRadius: "50%",
                height: "11.28vw",
                width: "11.28vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={
                  mobTab === 2
                    ? IconConstants.HouseKeepNavActive
                    : IconConstants.HouseKeepNav
                }
                alt=""
              />
            </Box>
          }
        />
      </BottomNavigation>

      <Grid container gap={{ xs: "3.08vw", sm: "1.11vw" }}>
        <Grid
          item
          xs={11.15}
          sm={7.3}
          // md={4.75}
          md={4.81}
          order={1}
          sx={{
            // width:"32.43vw",
            // height: "15.28vw",
            padding: { xs: "2.5% 0", sm: "1.1% 0" },
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            display: {
              xs: mobTab != 0 || isCalendarExpand ? "none" : "initial",
              sm: isCalendarExpand ? "none" : "initial",
            },
            margin: "0 auto",
          }}
        >
          <Box sx={{ width: "93%", margin: "0 auto" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "99%",
                margin: "0 auto 4.3% auto",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "#252525",
                  fontSize: { xs: "3.59vw", sm: "1.92vw", md: "1.12vw" },
                }}
              >
                {t("pages.home_page.room_occupancy.room_occupancy")}
              </Typography>

              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#4557FF",
                  fontSize: { xs: "3.59vw", sm: "1.92vw", md: "1.12vw" },
                }}
              >
                {t("pages.home_page.room_occupancy.total_rooms")} :{" "}
                <span style={{ fontWeight: 700 }}>{totalRooms}</span>
              </Typography>
            </Box>

            <Grid container gap={{ xs: "1.8vw", sm: "1.12vw", md: "0.65vw" }}>
              <Grid item xs={5.86}>
                {roomOccupancyData.otherOptions.map((d, roomOccupancyInd) => (
                  <Grid
                    item
                    key={roomOccupancyInd}
                    xs={12}
                    sx={{
                      padding: { xs: "0.6% 0", sm: "2.5% 0", md: "6% 0" },
                      borderRadius: "8px",
                      boxShadow:
                        roomsFlag == d.name
                          ? "0px 4px 4px 0px #0000000D"
                          : "none",
                      border: `1px solid ${
                        roomsFlag == d.name ? d.backgroundColor : d.borderColor
                      }`,
                      backgroundColor: d.backgroundColor,
                      transition: "all 0.3s ease",
                      cursor: "pointer",
                      marginBottom: roomOccupancyInd === 0 && "5.2%",
                    }}
                    onClick={() => {
                      if (d.name == roomsFlag) {
                        handleResetFilters();
                      } else {
                        setTodaysFilter();
                        resetActivityFilters(0);
                        setRoomsFlag(d.name);
                        setSelectionArray(d.occupancyData);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        rowGap: { xs: "3.59vw", sm: 0 },
                        alignItems: { md: "center" },
                        justifyContent: "center",
                        width: "88%",
                        margin: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            height: {
                              xs: "8.21vw",
                              sm: "3.84vw",
                              md: "1.67vw",
                            },
                            width: {
                              xs: "8.21vw",
                              sm: "3.84vw",
                              md: "1.67vw",
                            },
                          }}
                        >
                          <img
                            src={d.name == roomsFlag ? d.activeIcon : d.icon}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Box>
                        <Typography
                          sx={{
                            color: d.name == roomsFlag ? "#FFFFFF" : "#252525",
                            fontWeight: 500,
                            fontSize: {
                              xs: "3.59vw",
                              sm: "1.68vw",
                              md: "0.97vw",
                            },
                          }}
                        >
                          {t(
                            `pages.home_page.room_occupancy.${d.name.toLowerCase()}`
                          )}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: {
                            xs: "8.21vw",
                            sm: "3.36vw",
                            md: "1.94vw",
                          },
                          fontWeight: 700,
                          color: d.name == roomsFlag ? "#FFFFFF" : d.color,
                          marginLeft: "auto",
                        }}
                      >
                        {d.value === 0
                          ? "0"
                          : d.value.toString().padStart(2, "0")}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>

              <Grid
                item
                xs={5.86}
                sx={{
                  padding: { xs: "1.4% 0", sm: "2.2% 0", md: "2.5% 0" },
                  borderRadius: "8px",
                  boxShadow:
                    roomsFlag == roomOccupancyData.allocatedOptions.name
                      ? "0px 4px 4px 0px #0000000D"
                      : "none",
                  border: `1px solid ${
                    roomsFlag == roomOccupancyData.allocatedOptions.name
                      ? roomOccupancyData.allocatedOptions.backgroundColor
                      : roomOccupancyData.allocatedOptions.borderColor
                  }`,
                  backgroundColor:
                    roomOccupancyData.allocatedOptions.backgroundColor,
                  transition: "all 0.3s ease",
                  cursor: "pointer",
                }}
              >
                <Grid
                  container
                  rowGap={{ xs: "1.8vw", sm: "1.12vw", md: "0.65vw" }}
                >
                  <Grid
                    item
                    xs={10}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      rowGap: { xs: "3.59vw", sm: 0 },
                      alignItems: { xs: "initial", md: "center" },
                      justifyContent: "center",
                      width: "88%",
                      margin: "0 auto 2.8% auto",
                    }}
                    onClick={() => {
                      if (
                        roomOccupancyData.allocatedOptions.name == roomsFlag
                      ) {
                        handleResetFilters();
                      } else {
                        setTodaysFilter();
                        resetActivityFilters(0);
                        setRoomsFlag(roomOccupancyData.allocatedOptions.name);
                        setSelectionArray(
                          roomOccupancyData.allocatedOptions.occupancyData
                        );
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          height: {
                            xs: "8.21vw",
                            sm: "3.84vw",
                            md: "1.67vw",
                          },
                          width: {
                            xs: "8.21vw",
                            sm: "3.84vw",
                            md: "1.67vw",
                          },
                        }}
                      >
                        <img
                          src={
                            roomOccupancyData.allocatedOptions.name == roomsFlag
                              ? roomOccupancyData.allocatedOptions.activeIcon
                              : roomOccupancyData.allocatedOptions.icon
                          }
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          color:
                            roomOccupancyData.allocatedOptions.name == roomsFlag
                              ? "#FFFFFF"
                              : "#252525",
                          fontWeight: 500,
                          fontSize: {
                            xs: "3.59vw",
                            sm: "1.44vw",
                            md: "0.83vw",
                          },
                        }}
                      >
                        {t(
                          `pages.home_page.room_occupancy.${roomOccupancyData.allocatedOptions.name.toLowerCase()}`
                        )}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        fontSize: {
                          xs: "8.21vw",
                          sm: "3.36vw",
                          md: "1.94vw",
                        },
                        fontWeight: 700,
                        color: roomOccupancyData.allocatedOptions.color,
                        marginLeft: "auto",
                      }}
                    >
                      {roomOccupancyData.allocatedOptions.value === 0
                        ? "0"
                        : roomOccupancyData.allocatedOptions.value
                            .toString()
                            .padStart(2, "0")}
                    </Typography>
                  </Grid>

                  {roomOccupancyData.allocatedOptions.subOptions.map(
                    (option, optionInd) => {
                      return (
                        <Grid
                          item
                          key={optionInd}
                          xs={5.4}
                          sx={{
                            padding: {
                              xs: "1.4% 0",
                              sm: "5% 0",
                              md: "4.5% 0",
                            },
                            borderRadius: "8px",
                            boxShadow:
                              roomsFlag == option.name
                                ? "0px 4px 4px 0px #0000000D"
                                : "none",
                            border: `1px dashed ${
                              roomsFlag == option.name
                                ? option.backgroundColor
                                : option.borderColor
                            }`,
                            backgroundColor: option.backgroundColor,
                            transition: "all 0.3s ease",
                            cursor: "pointer",
                            margin: "auto",
                          }}
                          onClick={() => {
                            if (option.name == roomsFlag) {
                              handleResetFilters();
                            } else {
                              setTodaysFilter();
                              resetActivityFilters(0);
                              setRoomsFlag(option.name);
                              setSelectionArray(option.occupancyData);
                            }
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: { xs: "3.59vw", sm: 0 },
                              alignItems: "center",
                              justifyContent: "center",
                              width: "88%",
                              margin: "auto",
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  option.name == roomsFlag
                                    ? "#FFFFFF"
                                    : "#252525",
                                fontWeight: 500,
                                fontSize: {
                                  xs: "3.59vw",
                                  sm: "1.44vw",
                                  md: "0.83vw",
                                },
                              }}
                            >
                              {t(
                                `pages.home_page.room_occupancy.${option.name.toLowerCase()}`
                              )}
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "8.21vw",
                                  sm: "2.4vw",
                                  md: "1.39vw",
                                },
                                fontWeight: 700,
                                color:
                                  option.name == roomsFlag
                                    ? "#FFFFFF"
                                    : option.color,
                              }}
                            >
                              {option.value === 0
                                ? "0"
                                : option.value.toString().padStart(2, "0")}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          item
          xs={11.15}
          sm={4.55}
          md={2.7}
          order={2}
          sx={{
            padding: { xs: "2.5% 0 3.5% 0", sm: "1.1% 0" },
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            display: {
              xs: mobTab != 2 && "none",
              sm: isCalendarExpand ? "none" : "initial",
            },
            margin: { xs: "0 auto 0.5% auto", sm: "0 auto" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "92%",
              margin: "0 auto",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                color: "#252525",
                fontSize: { xs: "3.59vw", sm: "1.92vw", md: "1.12vw" },
                marginBottom: { xs: "5%", sm: "7.7%" },
              }}
            >
              {/* Housekeeping */}
              {t("pages.home_page.housekeeping.housekeeping")}
            </Typography>
            {houseCleaningData.map((d, ind) => {
              return (
                <Box
                  key={ind}
                  sx={{
                    width: { xs: "97.5%", sm: "95.5%" },
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor:
                      d.name == roomsFlag ? "#DEA829" : "#FFFFFF",
                    borderRadius: "6px",
                    cursor: "pointer",
                    border:
                      d.name == roomsFlag
                        ? "1px solid #DEA829"
                        : "1px solid #FFDE90",
                    padding: "2% 7% 2% 5%",
                    marginBottom:
                      ind == houseCleaningData.length - 1 ? 0 : "4.5%",
                    transition: "all 0.3s ease",
                  }}
                  onClick={() => {
                    if (d.name === roomsFlag) {
                      handleResetFilters();
                    } else {
                      resetActivityFilters(0);
                      setHouseKeepStatus(d.statusId);
                      setRoomsFlag(d.name);
                      setSelectionArray(d.houseKeepingData);
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "0.5vw",
                    }}
                  >
                    <Box
                      sx={{
                        height: { xs: "7.18vw", sm: "3.84vw", md: "1.94vw" },
                        width: { xs: "7.18vw", sm: "3.84vw", md: "1.94vw" },
                      }}
                    >
                      <img
                        src={d.name === roomsFlag ? d.activeIcon : d.icon}
                        alt=""
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "3.08vw", sm: "1.68vw", md: "0.97vw" },
                        fontWeight: 500,
                        color:
                          d.name == roomsFlag
                            ? "#FFFFFF"
                            : { xs: "#DEA829", sm: "#252525" },
                      }}
                    >
                      {t(
                        `pages.home_page.housekeeping.${d.name.toLowerCase()}`
                      )}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "5.13vw", sm: "2.88vw", md: "1.39vw" },
                      fontWeight: 700,
                      color: d.name == roomsFlag ? "#FFFFFF" : "#DEA829",
                    }}
                  >
                    {d.value == 0 ? "0" : d.value.toString().padStart(2, "0")}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>

        <Grid
          sx={{
            // marginBottom: "2%",
            borderRadius: "8px",
            background: "#FFFFFF",
            // padding: "4% 2%",
            // marginTop: { xs: "2%", md: 0 },
            // width: "28.06vw",
            padding: { xs: "3% 2%", sm: "2% 0", md: "1.16% 0" },
            // height: "15.28vw",
            // height: "21.09vw",
            display: {
              xs: mobTab != 1 && "none",
              sm: "initial",
              md: isCalendarExpand ? "none" : "initial",
            },
            margin: { xs: "0 auto 0.5% auto", sm: "0" },
          }}
          item
          xs={11.15}
          sm={5.9}
          md={4.16}
          order={{ xs: 3, sm: 4, lg: 3 }}
        >
          <Box
            sx={{
              width: { xs: "99%", sm: "92.5%", md: "93%" },
              margin: "0 auto",
              height: { xs: "50vw", sm: "auto" },
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                color: "#252525",
                marginBottom: { xs: "4%", sm: "3.5%", md: "5%" },
                fontSize: { xs: "3.59vw", sm: "1.92vw", md: "1.12vw" },
              }}
            >
              {/* Today's Arrival & Departure */}
              {t(
                "pages.home_page.todays_arrival_and_departure.todays_arrival_and_departure"
              )}
            </Typography>

            <Grid
              container
              direction={{ xs: "row", sm: "column", md: "row" }}
              gap={{ xs: "2.56vw", sm: "0.56vw" }}
            >
              {todaysArrivalAndDeparture.map((val, todayInd) => (
                <Fragment key={todayInd}>
                  <Grid
                    item
                    xs={5.6}
                    sm={12}
                    md={5.6}
                    sx={{
                      display: { xs: "initial", sm: "flex", md: "initial" },
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        margin: {
                          sm: "0 3.5% 0 0",
                          xs: "0 0 3.5% 0",
                          md: "0 0 3.5% 0",
                        },
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          border:
                            activityFlag === val.name
                              ? "1px solid #DEA829"
                              : "1px solid #FFE19B",
                          backgroundColor:
                            activityFlag === val.name ? "#DEA829" : "#FFFBF1",
                          borderRadius: "8px",
                          boxShadow:
                            activityFlag === val.name
                              ? "0px 4px 4px 0px #0000000D"
                              : "none",
                          padding: {
                            xs: "3.5%",
                            sm: "8% 0",
                            md: "5% 0 0 0",
                          },
                          height: { sm: "16.79vw", md: "initial" },
                          transition: "all 0.3s ease",
                        }}
                      >
                        <Box
                          onClick={() => {
                            if (val.name == activityFlag) {
                              resetActivityFilters(0);
                            } else {
                              handleResetFilters();
                              setActivityFlag(val.name);
                              if (val.name == "Arrival") {
                                setTabIndex(0);
                                setArrivalTableData(val.todaysData);
                              } else {
                                setTabIndex(1);
                                setDepartureTableData(val.todaysData);
                              }
                            }
                          }}
                          sx={{
                            width: { xs: "100%", sm: "88%", md: "90%" },
                            margin: "0% auto",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: {
                                xs: "2vw",
                                sm: "1.54vw",
                                md: "0.52vw",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                height: {
                                  xs: "5.38vw",
                                  sm: "3.6vw",
                                  md: "1.46vw",
                                },
                                width: {
                                  xs: "5.38vw",
                                  sm: "3.6vw",
                                  md: "1.46vw",
                                },
                              }}
                            >
                              <img
                                src={
                                  activityFlag === val.name
                                    ? val.activeIcon
                                    : val.icon
                                }
                                alt=""
                                style={{ height: "100%", width: "100%" }}
                              />
                            </Box>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                color:
                                  val.name == activityFlag
                                    ? "#FFFFFF"
                                    : "#252525",
                                fontSize: {
                                  xs: "3.08vw",
                                  sm: "1.68vw",
                                  md: "0.83vw",
                                },
                              }}
                            >
                              {val.label}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "right",
                              marginTop: { xs: "6%", sm: "20%", md: 0 },
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  val.name == activityFlag
                                    ? "#FFFFFF"
                                    : "#DEA829",
                                fontWeight: 700,
                                fontSize: {
                                  xs: "6.15vw",
                                  sm: "3.84vw",
                                  md: "1.94vw",
                                },
                              }}
                            >
                              {val.value == 0
                                ? "0"
                                : val.value.toString().padStart(2, "0")}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container gap={{ xs: "4%", sm: "1.44vw", md: "2%" }}>
                      {val.subCounts.map((sub, subInd) => (
                        <Grid
                          key={subInd}
                          item
                          md={5.88}
                          sm={12}
                          xs={5.75}
                          sx={{
                            border:
                              activityFlag === sub.name
                                ? "1px solid #DEA829"
                                : "1px solid #FFE19B",
                            backgroundColor:
                              activityFlag === sub.name ? "#DEA829" : "#FFFBF1",
                            borderRadius: "8px",
                            textAlign: "center",
                            boxShadow:
                              activityFlag === sub.name
                                ? "0px 4px 4px 0px #0000000D"
                                : "none",
                            padding: { xs: "3.5% 0", sm: "2% 0", md: "3.5% 0" },
                            marginTop: { xs: "5%", sm: 0 },
                            height: {
                              xs: "14.559vw",
                              sm: "7.67vw",
                              md: "initial",
                            },
                            transition: "all 0.3s ease",
                          }}
                        >
                          <Button
                            variant="text"
                            fullWidth
                            onClick={() => {
                              if (sub.name === activityFlag) {
                                resetActivityFilters(0);
                              } else {
                                handleResetFilters();
                                setActivityFlag(sub.name);
                                if (val.name == "Arrival") {
                                  setTabIndex(0);
                                  setArrivalTableData(sub.todaysData);
                                } else {
                                  setTabIndex(1);
                                  setDepartureTableData(sub.todaysData);
                                }
                              }
                            }}
                            sx={{
                              textTransform: "none",
                              textAlign: "center",
                              padding: { xs: "3.5% 0", sm: "6% 0", md: 0 },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: {
                                  xs: "column",
                                  sm: "row",
                                  md: "column",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "80%",
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  color:
                                    sub.name == activityFlag
                                      ? "#FFFFFF"
                                      : "#4C4C4C",
                                  fontSize: {
                                    xs: "3.08vw",
                                    sm: "1.44vw",
                                    md: "0.83vw",
                                  },
                                }}
                              >
                                {sub.label}
                              </Typography>

                              <Typography
                                sx={{
                                  color:
                                    sub.name == activityFlag
                                      ? "#FFFFFF"
                                      : "#DEA829",
                                  fontWeight: 600,
                                  fontSize: {
                                    xs: "4.1vw",
                                    sm: "2.88vw",
                                    md: "1.67vw",
                                  },
                                }}
                              >
                                {sub.count == 0
                                  ? "0"
                                  : sub.count.toString().padStart(2, "0")}
                              </Typography>
                            </Box>
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  {todayInd % 2 == 0 && (
                    <Grid
                      item
                      xs={0}
                      sm={0.01}
                      sx={{
                        border: "0.5px solid #FFEFC9",
                        display: { xs: "none", sm: "initial" },
                      }}
                    ></Grid>
                  )}
                </Fragment>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            marginBottom: { xs: "22vw", sm: 0 },
            transition: "all 0.3s ease",
            padding: { xs: "3% 0 2.5% 0", sm: "1.5% 0" },
            display: { xs: mobTab === 1 && "none", sm: "initial" },
            margin: "0 auto",
          }}
          item
          xs={11.15}
          sm={12}
          md={expanded || isCalendarExpand ? 12 : 7.67}
          order={{ xs: 3, md: 4 }}
        >
          <Box sx={{ width: "94%", margin: "0 auto" }}>
            {viewType !== "Calendar View" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: "1.9%",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "99%",
                      sm: "35%",
                      md: expanded ? "50%" : "35%",
                    },
                    margin: { xs: "3% auto 1% auto", sm: 0 },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.97vw" },
                    }}
                  >
                    {t("pages.home_page.room_view_section.showing_results_for")}
                    {!!roomsFlag ? (
                      <>
                        {"  "}
                        <span style={{ color: "#007FFF", fontWeight: "550" }}>
                          -{" "}
                          {!!searchText
                            ? roomsFlag === "All"
                              ? t("pages.home_page.room_view_section.all")
                              : roomsFlag
                            : t(
                                `pages.home_page.${
                                  ["Clean", "Assigned", "Dirty"].includes(
                                    roomsFlag
                                  )
                                    ? "housekeeping"
                                    : roomsFlag === "All"
                                    ? "room_view_section"
                                    : "room_occupancy"
                                }.${roomsFlag.toLowerCase()}`
                              )}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: { xs: "none", sm: "initial" },
                    width: { sm: "20%", md: expanded ? "15%" : "20%" },
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: "flex" },
                      border: "0.5px solid #FFDC8A",
                      borderRadius: "4px",
                      height: { xs: "7.692vw", sm: "3.597vw", md: "2.084vw" },
                    }}
                  >
                    {dashboardViewTypes.map((option, index) => (
                      <Box
                        key={option.value}
                        onClick={() => {
                          setViewType(option.value);
                          localStorage.setItem("viewType", option.value);
                        }}
                        sx={{
                          width: "50%",
                          backgroundColor:
                            viewType === option.value ? "#DEA829" : "#F9F1DD",
                          borderRadius:
                            index === 0
                              ? "3px 0 0 3px"
                              : index === dashboardViewTypes.length - 1
                              ? "0 3px 3px 0"
                              : 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transition: "all 0.3s ease",
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          sx={{
                            height: {
                              xs: "5.128vw",
                              sm: "2.398vw",
                              md: "1.389vw",
                            },
                            width: {
                              xs: "5.128vw",
                              sm: "2.398vw",
                              md: "1.389vw",
                            },
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              viewType === option.value
                                ? option.activeIcon
                                : option.icon
                            }
                            alt={option.value}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: 1, sm: "40%", md: expanded ? "30%" : "40%" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControl
                      sx={{
                        width: { xs: "55%", sm: "71%" },
                      }}
                    >
                      <OutlinedInput
                        id="outlined-adornment-search"
                        type={"text"}
                        autoComplete="off"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#FFDC8A !important",
                            borderWidth: "1px",
                          },
                          fontSize: {
                            xs: "2.56vw",
                            sm: "1.44vw",
                            md: "0.83vw",
                          },
                          height: { xs: "7.69vw", sm: "3.6vw", md: "2.08vw" },
                          backgroundColor: "#FFFBF1",
                          borderRadius: "50px",
                          color: "#868686",
                        }}
                        onInput={(e) => {
                          const value = e.target.value;
                          e.target.value = value
                            // .replace(/[^A-Za-z0-9\s]/g, "")
                            .replace(/^\s+/, "")
                            .trim()
                            .toUpperCase();
                        }}
                        placeholder={t(
                          "pages.home_page.room_view_section.search_by_room_number"
                        )}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={(e) => handleSearch()}
                              size="small"
                            >
                              <img src={IconConstants.SearchIcon} alt="" />
                            </IconButton>
                          </InputAdornment>
                        }
                        value={searchText}
                        onChange={(e) => {
                          const value = e.target.value;
                          setSearchFlag(false);
                          setSearchText(
                            value
                              // .replace(/[^A-Za-z0-9\s]/g, "")
                              .replace(/^\s+/, "")
                              .trim()
                              .toUpperCase()
                          );
                          if (e.target.value.length == 0) {
                            setRoomsFlag("All");
                            setSelectionArray([{ isAllSelected: true }]);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            handleSearch();
                          }
                        }}
                      />
                    </FormControl>

                    <Box sx={{ width: { xs: "20%", sm: "12%" } }}>
                      <Tooltip
                        title={t("pages.home_page.room_view_section.reset")}
                      >
                        <Box
                          sx={{
                            height: {
                              xs: "7.69vw",
                              sm: "3.60vw",
                              md: "2.08vw",
                            },
                            width: { xs: "7.69vw", sm: "3.60vw", md: "2.08vw" },
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={IconConstants.ResetButton}
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                            onClick={() => handleResetFilters()}
                          />
                        </Box>
                      </Tooltip>
                    </Box>

                    <Box sx={{ width: { xs: "30%", sm: "10%" } }}>
                      <Box
                        sx={{
                          display: { xs: "flex", sm: "none" },
                          border: "0.5px solid #FFDC8A",
                          borderRadius: "4px",
                          height: {
                            xs: "7.692vw",
                            sm: "3.597vw",
                            md: "2.084vw",
                          },
                        }}
                      >
                        {dashboardViewTypes.map((option, index) => (
                          <Box
                            key={option.value}
                            onClick={() => {
                              setViewType(option.value);
                              localStorage.setItem("viewType", option.value);
                            }}
                            sx={{
                              width: "50%",
                              backgroundColor:
                                viewType === option.value
                                  ? "#DEA829"
                                  : "#F9F1DD",
                              borderRadius:
                                index === 0
                                  ? "3px 0 0 3px"
                                  : index === dashboardViewTypes.length - 1
                                  ? "0 3px 3px 0"
                                  : 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              transition: "all 0.3s ease",
                              cursor: "pointer",
                            }}
                          >
                            <Box
                              sx={{
                                height: {
                                  xs: "5.128vw",
                                  sm: "2.398vw",
                                  md: "1.389vw",
                                },
                                width: {
                                  xs: "5.128vw",
                                  sm: "2.398vw",
                                  md: "1.389vw",
                                },
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  viewType === option.value
                                    ? option.activeIcon
                                    : option.icon
                                }
                                alt={option.value}
                              />
                            </Box>
                          </Box>
                        ))}
                      </Box>

                      <Box sx={{ display: { xs: "none", sm: "initial" } }}>
                        <Tooltip
                          title={t(
                            `pages.home_page.room_view_section.${
                              expanded ? "collapse" : "expand"
                            }`
                          )}
                        >
                          <Box
                            sx={{
                              height: { xs: 30, sm: "3.60vw", md: "2.08vw" },
                              width: { xs: 30, sm: "3.60vw", md: "2.08vw" },
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setExpanded(!expanded);
                            }}
                          >
                            <img
                              src={
                                isMobile
                                  ? expanded
                                    ? IconConstants.TabExpand
                                    : IconConstants.TabShrink
                                  : expanded
                                  ? IconConstants.ExpandRevert
                                  : IconConstants.ExpandButton
                              }
                              style={{
                                height: "100%",
                                width: "100%",
                                transition: "all 0.3s ease",
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              ""
            )}

            <Box
              className={isCalendarView ? "" : "custom-scrollbar"}
              sx={{
                height: {
                  xs: isCalendarView && isCalendarExpand ? "78vh" : "auto",
                  sm: isCalendarView ? "auto" : "51.28vw",
                  md: isCalendarView ? "auto" : "23.9vw",
                },
                overflowY: isCalendarView ? "initial" : "scroll",
                // scrollbarWidth: "thin",
                scrollBehavior: "smooth",
                paddingRight: isCalendarExpand ? 0 : "0.5%",
                // scrollbarColor: "#DEA829 #F9F1DD",
                // "&::-webkit-scrollbar": {
                //   borderRadius: "15px",
                // },
                // "&::-webkit-scrollbar-thumb": {
                //   borderRadius: "15px",
                // },
                // width:"48.33vw",

                display: { xs: expanded && "none", md: "block" },
              }}
            >
              {viewType === "Room View" ? (
                <>
                  {roomTypes?.length > 0 ? (
                    roomTypes.map((roomType, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          backgroundColor: "#F3F3F3",
                          marginBottom:
                            index == roomTypes?.length - 1 ? 0 : "1.65%",
                          borderRadius: "8px",
                          width: "100%",
                          padding: { xs: "3% 0", sm: "1.6% 0" },
                        }}
                      >
                        <Box
                          sx={{
                            width:
                              roomType?.rooms.length > (expanded ? 25 : 16)
                                ? "100%"
                                : "96%",
                            margin: "0 auto",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: {
                                xs: "95%",
                                sm:
                                  roomType?.rooms.length > (expanded ? 25 : 16)
                                    ? "94%"
                                    : "98%",
                              },
                              margin: {
                                xs: "0 auto 2.5% auto",
                                sm: "0 auto 1.5% auto",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#252525",
                                fontWeight: 600,
                                fontSize: {
                                  xs: "3.59vw",
                                  sm: "1.92vw",
                                  md: "0.97vw",
                                },
                              }}
                            >
                              {roomType?.room_type_name}
                            </Typography>
                            {roomType?.rooms.length > 0 && (
                              <Tooltip
                                title={t(
                                  "pages.home_page.room_view_section.expand"
                                )}
                              >
                                <Box
                                  sx={{
                                    width: {
                                      xs: "5.13vw",
                                      sm: "2.88vw",
                                      md: "1.39vw",
                                    },
                                    height: {
                                      xs: "5.13vw",
                                      sm: "2.88vw",
                                      md: "1.39vw",
                                    },
                                  }}
                                  onClick={() =>
                                    setModalLabel(roomType?.room_type_name)
                                  }
                                >
                                  <img
                                    src={IconConstants.ExpandIcon}
                                    style={{
                                      cursor: "pointer",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                            )}
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: { xs: "95%", sm: 1 },
                              margin: "0 auto",
                            }}
                          >
                            <Box
                              onClick={() => {
                                handleScroll(-40, index);
                              }}
                              sx={{
                                cursor: "pointer",
                                display:
                                  roomType?.rooms.length > (expanded ? 25 : 16)
                                    ? { xs: "none", sm: "initial" }
                                    : "none",
                              }}
                            >
                              <KeyboardArrowLeftRounded fontSize="small" />
                            </Box>
                            <ScrollContainer id={`scroll-container${index}`}>
                              {roomType?.rooms.length > 0 ? (
                                <ScrollItem>
                                  <Box
                                    sx={{
                                      marginBottom: {
                                        xs: "2.4vw",
                                        md: "1.11vw",
                                      },
                                    }}
                                  >
                                    <IndividualRoomContainer
                                      selectionArray={selectionArray}
                                      currentRoomType={roomType?.room_type_name}
                                      rooms={
                                        roomType?.rooms.length > 7
                                          ? roomType?.rooms.filter(
                                              (v) =>
                                                v.room_id % 2 == 0 &&
                                                v.room_id != null
                                            )
                                          : roomType?.rooms.filter(
                                              (room) => room.room_id != null
                                            )
                                      }
                                      searchText={searchText}
                                      searchFlag={searchFlag}
                                      currentHouseKeep={houseKeepStatus}
                                      todaysFilter={todaysFilter}
                                      handleResetFilters={handleResetFilters}
                                    />
                                  </Box>
                                  {roomType?.rooms.length > 7 && (
                                    <IndividualRoomContainer
                                      selectionArray={selectionArray}
                                      currentRoomType={roomType?.room_type_name}
                                      rooms={roomType?.rooms.filter(
                                        (v) =>
                                          v.room_id % 2 != 0 &&
                                          v.room_id != null
                                      )}
                                      searchText={searchText}
                                      searchFlag={searchFlag}
                                      currentHouseKeep={houseKeepStatus}
                                      todaysFilter={todaysFilter}
                                      handleResetFilters={handleResetFilters}
                                    />
                                  )}
                                </ScrollItem>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 1,
                                    paddingBottom: "2.5%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        xs: "3.08vw",
                                        sm: "1.44vw",
                                        md: "0.97vw",
                                      },
                                    }}
                                  >
                                    No rooms
                                  </Typography>
                                </Box>
                              )}
                            </ScrollContainer>
                            <Box
                              onClick={() => {
                                handleScroll(40, index);
                              }}
                              sx={{
                                cursor: "pointer",
                                display:
                                  roomType?.rooms.length > (expanded ? 25 : 16)
                                    ? { xs: "none", sm: "initial" }
                                    : "none",
                              }}
                            >
                              <KeyboardArrowRightRounded fontSize="small" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        minHeight: "50vh",
                        fontWeight: 700,
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "3.08vw",
                            sm: "1.44vw",
                            md: "0.97vw",
                          },
                        }}
                      >
                        No Results Found
                      </Typography>
                    </Box>
                  )}
                </>
              ) : viewType === "Floor View" ? (
                <>
                  {floors?.length > 0 ? (
                    <Box
                      sx={{
                        background: "#F7F7F7",
                        borderRadius: "8px",
                        padding: { xs: "2% 0", sm: "1.5% 0" },
                        marginTop: "0.5%",
                      }}
                    >
                      {floors.map((floor) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "2% 0 3% 0",
                          }}
                        >
                          <Box
                            sx={{
                              width: { xs: "12%", sm: "7%" },
                              textAlign: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "4px",
                                width: {
                                  md: "2.22vw",
                                  sm: "3.84vw",
                                  xs: "7.18vw",
                                },
                                height: {
                                  md: "2.22vw",
                                  sm: "3.84vw",
                                  xs: "7.18vw",
                                },
                                background: "#DEA829",
                                margin: "0 auto",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#FFFFFF",
                                  fontSize: {
                                    xs: "3.59vw",
                                    sm: "2.396vw",
                                    md: "1.39vw",
                                  },
                                  fontWeight: 700,
                                }}
                              >
                                {floor.floor_code}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              width: { xs: "79%", sm: "88%", md: "84%" },
                              height: "100%",
                            }}
                          >
                            <ScrollContainer id="scroll-container">
                              <ScrollItem>
                                <IndividualRoomContainer
                                  selectionArray={selectionArray}
                                  rooms={floor?.roomStatus?.top?.items.sort(
                                    (a, b) =>
                                      a?.position_index - b?.position_index
                                  )}
                                  searchText={searchText}
                                  searchFlag={searchFlag}
                                  currentHouseKeep={houseKeepStatus}
                                  todaysFilter={todaysFilter}
                                  handleResetFilters={handleResetFilters}
                                />
                                <Box
                                  sx={{
                                    backgroundColor: "#DEDEDE",
                                    display: "block",
                                    margin: { xs: "2vw", sm: "0.83vw 0" },
                                    height: { xs: "0.5vw", sm: "0.25vw" },
                                    borderRadius: "10px",
                                    width: "100%",
                                  }}
                                ></Box>
                                <IndividualRoomContainer
                                  selectionArray={selectionArray}
                                  rooms={floor?.roomStatus?.bottom?.items.sort(
                                    (a, b) =>
                                      a?.position_index - b?.position_index
                                  )}
                                  searchText={searchText}
                                  searchFlag={searchFlag}
                                  currentHouseKeep={houseKeepStatus}
                                  todaysFilter={todaysFilter}
                                  handleResetFilters={handleResetFilters}
                                />
                              </ScrollItem>
                            </ScrollContainer>
                          </Box>
                          <Box
                            sx={{
                              width: { xs: "9%", sm: "6%" },
                              textAlign: "center",
                            }}
                          >
                            {(floor?.roomStatus?.top.items.length > 0 ||
                              floor?.roomStatus?.bottom.items.length > 0) && (
                              <Tooltip
                                title={t(
                                  `pages.home_page.room_view_section.expand`
                                )}
                              >
                                <Box
                                  sx={{
                                    width: {
                                      xs: "5.13vw",
                                      sm: "2.88vw",
                                      md: "1.67vw",
                                    },
                                    height: {
                                      xs: "5.13vw",
                                      sm: "2.88vw",
                                      md: "1.67vw",
                                    },
                                    cursor: "pointer",
                                    margin: "0 auto",
                                  }}
                                >
                                  <img
                                    src={IconConstants.ExpandGrey}
                                    onClick={() =>
                                      setModalLabel(floor.floor_code)
                                    }
                                    style={{ height: "100%", width: "100%" }}
                                  />
                                </Box>
                              </Tooltip>
                            )}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        minHeight: "23vh",
                        fontWeight: 700,
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: { xs: 14, sm: "1.44vw", md: "0.97" } }}
                      >
                        No Results Found
                      </Typography>
                    </Box>
                  )}
                </>
              ) : (
                <Calendar
                  roomTypeData={roomTypes}
                  isExpanded={isCalendarExpand}
                  setIsExpand={setIsCalendarExpand}
                  viewType={viewType}
                  setViewType={setViewType}
                />
              )}
            </Box>

            {viewType !== "Calendar View" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                  marginTop: "1.5%",
                }}
              >
                {roomLabels?.map((d) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "4%",
                      }}
                    >
                      <Box
                        sx={{
                          width: { xs: "2.5vw", sm: "1.3vw", md: "1vw" },
                          height: { xs: "2.5vw", sm: "1.3vw", md: "1vw" },
                          backgroundColor: d.color,
                          borderRadius: { xs: "2px", sm: "2px" },
                          marginRight: { xs: "1vw", sm: "0.7vw", sm: "0.5vw" },
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "3.08vw",
                            sm: "1.44vw",
                            md: "0.97vw",
                          },
                          color: "#8D8D8D",
                          fontWeight: 500,
                        }}
                      >
                        {t(
                          `pages.home_page.room_occupancy.${d.name.toLowerCase()}`
                        ) +
                          " " +
                          t("pages.home_page.room_view_section.room")}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={11.15}
          sm={5.9}
          md={expanded || isCalendarExpand ? 0 : 4.16}
          order={5}
          sx={{
            height: { xs: "auto", sm: "auto" },
            padding: "1% 0",
            paddingBottom: { xs: "20vw", sm: 0 },
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            marginBottom: { xs: "1%", sm: 0 },
            transition: "all 0.3s ease",
            display: {
              xs: mobTab != 1 ? "none" : expanded && "none",
              sm: "initial",
              md: expanded || isCalendarExpand ? "none" : "initial",
            },
            margin: "0 auto",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "3.59vw", sm: "1.92vw", md: "1.12vw" },
              fontWeight: 600,
              color: "#252525",
              margin: "2% 3%",
            }}
          >
            {/* Today's Activity */}
            {t("pages.home_page.todays_activity.todays_activity")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: 1,
              alignItems: "center",
              padding: "1.3% 0",
            }}
          >
            {activityTabsData.map((a, aIndex) => {
              return (
                <Button
                  key={aIndex}
                  onClick={() => {
                    resetActivityFilters(aIndex);
                  }}
                  sx={{
                    width: "33.3%",
                    backgroundColor:
                      aIndex === tabIndex ? "#DEA829" : "#F9F1DD",
                    height: "100%",
                    cursor: "pointer",
                    textTransform: "none",
                    fontWeight: 500,
                    color: aIndex === tabIndex ? "#FFFFFF" : "#252525",
                    fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.97vw" },
                    borderRadius: 0,
                    textWrap: "nowrap",
                    "&:hover": {
                      backgroundColor:
                        aIndex === tabIndex ? "#DEA829" : "#DEA829",
                    },
                  }}
                  startIcon={
                    <Box>
                      <Box
                        sx={{
                          height: { xs: "3.85vw", sm: "2.4vw", md: "1.67vw" },
                          width: { xs: "3.85vw", sm: "2.4vw", md: "1.67vw" },
                          lineHeight: 0,
                        }}
                      >
                        <img
                          src={aIndex === tabIndex ? a.active : a.inactive}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Box>
                    </Box>
                  }
                >
                  {t(
                    `pages.home_page.todays_activity.${a.name
                      .toLowerCase()
                      .replace(/\s+/g, "_")}`
                  )}
                </Button>
              );
            })}
          </Box>
          <Box
            sx={{
              height: { xs: "44dvh", sm: "30vw", md: "21.5vw" },
              overflow: "scroll",
              scrollbarWidth: "none",
            }}
          >
            {[
              <ArrivalActivityTable tableData={arrivalTableData} />,
              <DepartureActivityTable tableData={departureTableData} />,
              <PaymentsDueActivityTable tableData={pendingDue} />,
            ][tabIndex] || <>No data available</>}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            borderRadius: { xs: "10px", md: "18px" },
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: { xs: "4%", md: "2%" },
            }}
          >
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontSize: { xs: "5.13vw", sm: "2.8vw", md: "1.67vw" },
                  color: "#252525",
                  fontWeight: 600,
                }}
              >
                {viewType === "Room View"
                  ? `${modalLabel} Rooms`
                  : `Floor : ${modalLabel}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                justifyContent: "right",
                columnGap: { xs: "7vw", sm: "1.39vw" },
              }}
            >
              {filters.map((f) => (
                <Tooltip
                  title={t(
                    `pages.home_page.room_occupancy.${f.label.toLowerCase()}`
                  )}
                  key={f.label}
                >
                  <Box
                    sx={{
                      width: { xs: "2vw", sm: "3.36vw", md: "1.94vw" },
                      height: { sm: "3.36vw", md: "1.94vw" },
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setHouseKeepStatus(null);
                      setTodaysFilter();
                      setSearchText("");
                      setSearchFlag(false);
                      if (f.totalData === selectionArray) {
                        setSelectionArray([{ isAllSelected: true }]);
                      } else {
                        setSelectionArray(f.totalData);
                      }
                    }}
                  >
                    <img
                      src={
                        f.totalData === selectionArray ? f.activeIcon : f.icon
                      }
                      alt=""
                    />
                  </Box>
                </Tooltip>
              ))}
              <Tooltip title={t("pages.home_page.room_view_section.collapse")}>
                <Box
                  sx={{
                    width: { xs: "7vw", sm: "3.36vw", md: "1.94vw" },
                    height: { sm: "3.36vw", md: "1.94vw" },
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                >
                  <img src={IconConstants.Shrink} alt="" />
                </Box>
              </Tooltip>
            </Box>
          </Box>

          {viewType === "Room View"
            ? currentRoomTypeArray?.length > 0 &&
              currentRoomTypeArray.map((roomType) => (
                <IndividualRoomContainer
                  selectionArray={selectionArray}
                  currentRoomType={roomType?.room_type_name}
                  rooms={roomType?.rooms}
                  searchText={searchText}
                  searchFlag={searchFlag}
                  currentHouseKeep={houseKeepStatus}
                  todaysFilter={todaysFilter}
                  handleResetFilters={handleResetFilters}
                />
              ))
            : currentFloorTypeArray?.length > 0 &&
              currentFloorTypeArray.map((floor) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <IndividualRoomContainer
                    selectionArray={selectionArray}
                    rooms={floor?.roomStatus?.top?.items.sort(
                      (a, b) => a?.position_index - b?.position_index
                    )}
                    searchText={searchText}
                    searchFlag={searchFlag}
                    currentHouseKeep={houseKeepStatus}
                    todaysFilter={todaysFilter}
                    handleResetFilters={handleResetFilters}
                  />
                  <Box
                    sx={{
                      backgroundColor: "#DEDEDE",
                      display: "block",
                      margin: "5px 0px",
                      height: "0.2vw",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  ></Box>
                  <IndividualRoomContainer
                    selectionArray={selectionArray}
                    rooms={floor?.roomStatus?.bottom?.items.sort(
                      (a, b) => a?.position_index - b?.position_index
                    )}
                    searchText={searchText}
                    searchFlag={searchFlag}
                    currentHouseKeep={houseKeepStatus}
                    todaysFilter={todaysFilter}
                    handleResetFilters={handleResetFilters}
                  />
                </Box>
              ))}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: { xs: "5%", sm: "3%", md: "1%" },
            }}
          >
            {roomLabels?.map((d) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "2%",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "2.5vw", sm: "1.3vw", md: "1vw" },
                      height: { xs: "2.5vw", sm: "1.3vw", md: "1vw" },
                      backgroundColor: d.color,
                      borderRadius: { xs: "2px", sm: "2px" },
                      marginRight: { xs: "1vw", sm: "0.7vw", sm: "0.5vw" },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.97vw" },
                      color: "#8D8D8D",
                      fontWeight: 500,
                    }}
                  >
                    {t(
                      `pages.home_page.room_occupancy.${d.name.toLowerCase()}`
                    ) +
                      " " +
                      t("pages.home_page.room_view_section.room")}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
