import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment, useCallback, useEffect, useState } from "react";
import {
  AddCircleOutline,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  RemoveCircleTwoTone,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { AddOrRemoveFormConfig } from "../../config/forms/AddOrRemovePerson-config";
import { END_POINT } from "../../utils/End-points";
import ApiClient from "../../apis/Api-client";
import { format } from "date-fns";
import {
  errorToster,
  successToster,
  warnToster,
} from "../../shared/toster/Toster";
import { TAX_PERCENT } from "../../utils/Constants";
import { Helper } from "../../utils/Helpers";
import {
  selectTriggered,
  setTriggered,
} from "../../core/store/redux/slices/DashboardApi-slice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ConfirmationDlgBox from "../common-components/confirmation-popup";
import ReactWebcam from "../arrival/reactWebcam/React-webcam";
import IconConstants from "../../utils/IconConstants";
import { useTranslation } from "react-i18next";
const {
  getBasePriceFromFomNetRent,
  getNetRetFromBasePrice,
  debounce,
  getLocalTimeFromUtc,
} = new Helper();

const AddOrRemovePerson = ({ modalName, data, handleClose }) => {
  const { api } = ApiClient();
  const isTriggered = useSelector(selectTriggered);
  const dispatch = useDispatch();
  const themeStyles = useTheme();
  const { t } = useTranslation();
  let primaryColor = themeStyles.palette.orange_theme.primary.main;
  let contrastText = themeStyles.palette.orange_theme.primary.contrastText;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [confirmationDlg, setConfirmationDlg] = useState(false);
  const [confirmationLabel, setConfirmationLabel] = useState("");
  const [formData, setFormData] = useState({});
  const isRemovePerson = modalName === "Remove Person";
  const {
    occupancy_key,
    person_base_price,
    expected_checkout_date,
    room_no,
    pax,
    no_of_adults,
    no_of_children,
    no_of_extra_persons,
    mobile,
  } = data;
  const [openCamera, setOpenCamera] = useState(false);
  const [capturedImage, setCapturedImage] = useState([]);
  const [defaultCamImage, setDefaultCamImage] = useState([]);
  const [hiddenCamImage, setHiddenCamImage] = useState([]);

  const methods = useForm({
    resolver: yupResolver(AddOrRemoveFormConfig),
    defaultValues: {
      inc_of_tax: data.inc_of_tax === 1 ? true : false,
      extra_persons: [{ addon_price: person_base_price }],
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    getValues,
    watch,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "extra_persons",
  });

  const { inc_of_tax, extra_persons } = watch();

  useEffect(() => {
    occupancy_key && getExtraPersonData();
  }, []);

  const getExtraPersonData = async () => {
    try {
      const response = await api.post(
        END_POINT.ADDON.GET_EXTRA_PERSON_HISTORY,
        { is_checkout_date_null: true },
        {},
        { occupancy_key: occupancy_key }
      );

      const { extra_persons, images } = response?.data?.data ?? {};

      if (isRemovePerson) {
        if (!extra_persons || !Array.isArray(extra_persons)) {
          console.warn("No valid extra person data found");
          return;
        }
        const arr = extra_persons.map((v) => ({
          addon_name: v.addon_name,
          addon_price: inc_of_tax
            ? getNetRetFromBasePrice(Number(v.addon_price), 1, TAX_PERCENT)
            : Number(v.addon_price),
          addon_id: v.addon_id,
          actual_checkout_date: format(new Date(), "yyyy-MM-dd HH:mm"),
        }));

        reset({
          extra_persons: arr,
        });
        setDefaultCamImage(images);
      } else {
        setHiddenCamImage(images?.length ? images?.map((v) => v?.key) : "");
      }
    } catch (error) {
      console.error("Failed to fetch extra person data:", error);
    }
  };

  const handleCloseModal = () => {
    handleClose();
    setTimeout(() => {
      reset({ extra_persons: [{}] });
    }, 500);
  };

  const hasEmptyFields = (obj) => {
    const fieldsToCheck = ["addon_name", "addon_price"];

    return (
      obj &&
      typeof obj === "object" &&
      fieldsToCheck.some(
        (field) =>
          obj[field] === null || obj[field] === "" || obj[field] === undefined
      )
    );
  };

  const formFields = (countInd) => {
    return {
      loopFields: [
        {
          label: "Name",
          name: `extra_persons.${countInd}.addon_name`,
          type: "text",
          size: "small",
          width: { xs: "100%", sm: "49%" },
          maxLength: 25,
          disable: isRemovePerson,
        },
        {
          label: "Rent",
          name: `extra_persons.${countInd}.addon_price`,
          type: "number",
          size: "small",
          width: { xs: "100%", sm: "49%" },
          disable: isRemovePerson,
          inputAdornment: !!getValues(
            `extra_persons.${countInd}.addon_price`
          ) && (
            <InputAdornment sx={{ p: 0 }}>
              <Typography
                sx={{
                  fontSize: { sm: "1.3vw", md: "0.833vw" },
                  color: "#AAAAAA",
                }}
              >
                {t("common_components.extra_person_popup.per_day")}
              </Typography>
            </InputAdornment>
          ),
        },
      ],
      commonFields: [
        {
          label: "Inc Of Tax",
          type: "checkbox",
          name: "inc_of_tax",
          disable: true,
          width: 1,
        },
      ],
    };
  };

  const renderField = (formField, field, error, countInd) => {
    const commonProps = {
      ...field,
      name: formField.name,
      size: formField.size,
      type: formField.type,
      label: t(
        `common_components.extra_person_popup.${formField.label.toLowerCase()}`
      ),
      autoComplete: "off",
      error: !!error?.message,
      helperText: error
        ? t(
            `common_components.extra_person_popup.alert.${error?.message
              .toLowerCase()
              .replace(/\s+/g, "_")}`
          )
        : "",
      disabled: formField?.disable,
      InputProps: {
        endAdornment: formField?.inputAdornment,
        sx: {
          fontSize: { xs: "4.1vw", sm: "1.619vw", md: "1.111vw" },
          // color: "#AAAAAA",
          fontWeight: "400",
        },
      },
      InputLabelProps: {
        sx: {
          fontSize: { xs: "4.1vw", sm: "1.619vw", md: "1.111vw" },
          fontWeight: 500,
          color: "#AAAAAA",
        },
      },
      FormHelperTextProps: {
        sx: { fontSize: { xs: "3.08vw", sm: "1.333vw", md: "0.833vw" } },
      },
    };

    const handleInput = (e) => {
      const { value } = e.target;
      e.target.value = formField.name.includes("addon_price")
        ? Number(value) === 0
          ? null
          : value.replace(/[^0-9]/g, "")
        : formField.type === "text"
        ? value.replace(/[0-9]/g, "").replace(/^\s+/, "")
        : value.replace(/^\s+/, "");
    };

    switch (formField?.type) {
      case "text":
      case "number":
        return (
          <TextField
            {...commonProps}
            value={watch(formField.name) || ""}
            onWheel={(e) => e.target.blur()}
            // onKeyDown={(e) => handleKeyEvents(e, formField?.name)}
            // onKeyUp={(e) => handleKeyEvents(e, formField?.name)}
            onInput={handleInput}
            // onChange={(e) => handleChange(e, countInd)}
            inputProps={{
              max: formField?.max,
              min: 0,
              maxLength: formField.maxLength || "",
            }}
            className={
              formField.name.includes("no_of_children") ||
              formField.name.includes("no_of_adults")
                ? ""
                : "NoSpinnerTextField no-spinner"
            }
          />
        );
      case "checkbox":
        return (
          <FormControlLabel
            {...field}
            sx={{
              margin: "0 0 0 auto",
              "& .MuiFormControlLabel-label": {
                fontSize: { sm: "1.3vw", md: "0.833vw" },
                color: "#5A5A5A",
              },
            }}
            value={inc_of_tax}
            name={formField.name}
            disabled={formField.disable}
            control={
              <Checkbox
                checked={inc_of_tax}
                size="small"
                checkedIcon={
                  <CheckBoxOutlined
                    fontSize="small"
                    sx={{
                      color: "#BEBEBE",
                      fontSize: { sm: "1.8vw", md: "1.2vw" },
                    }}
                  />
                }
                icon={
                  <CheckBoxOutlineBlankOutlined
                    fontSize="small"
                    sx={{
                      color: "#BEBEBE",
                      fontSize: { sm: "1.8vw", md: "1.2vw" },
                    }}
                  />
                }
                sx={{ p: "0.5vw 0.3vw" }}
              />
            }
            label={t(
              `common_components.extra_person_popup.${formField.label
                .toLowerCase()
                .replace(/\s+/g, "_")}`
            )}
            labelPlacement="end"
          />
        );
      default:
        return null;
    }
  };

  const handleRemove = (index) => {
    remove(index);
  };

  const onSubmit = async (data) => {
    const addonData = {
      ...data,
      images: [...capturedImage, ...hiddenCamImage],
    };
    if (
      addonData?.extra_persons?.length >
        pax - (no_of_children + no_of_adults + no_of_extra_persons) &&
      !isRemovePerson
    ) {
      setFormData(addonData);
      setConfirmationLabel(
        `${t(
          "common_components.extra_person_popup.alert.maximum_occupancy_reached_for_this_room"
        )}.\n ${t(
          "common_components.extra_person_popup.alert.are_you_sure_you_want_to_add_a_person"
        )} ?`
      );
      setConfirmationDlg(true);
      return;
    } else {
      debouncedAddOrUpdatePerson(addonData);
    }
  };

  const addOrUpdatePerson = async (data) => {
    try {
      const { inc_of_tax, extra_persons, images } = data;
      const addon_checkin_date = format(new Date(), "yyyy-MM-dd HH:mm");

      const processedPersons = extra_persons.map((person) => ({
        ...person,
        addon_price: inc_of_tax
          ? getBasePriceFromFomNetRent(person.addon_price, 1, TAX_PERCENT)
          : person.addon_price,
      }));

      const FormData = require("form-data");
      const formDataInstance = new FormData();
      formDataInstance.append("data", JSON.stringify({ mobile }));

      const imageUrls = images
        ?.filter((c) => typeof c === "string")
        .map((v) => ({ key: v }));

      if (imageUrls.length > 0) {
        formDataInstance.append(
          "addon_person_image",
          JSON.stringify(imageUrls)
        );
      }
      images?.forEach((file) => {
        if (typeof file !== "string") {
          formDataInstance.append("addon_person_image", file);
        }
      });
      if (
        addon_checkin_date >
          getLocalTimeFromUtc(expected_checkout_date).dateTimeString &&
        !isRemovePerson
      ) {
        errorToster(
          t(
            "common_components.extra_person_popup.alert.extend_stay_before_adding_the_person"
          )
        );
        return;
      }
      if (
        processedPersons[0]?.actual_checkout_date >
          getLocalTimeFromUtc(expected_checkout_date).dateTimeString &&
        isRemovePerson
      ) {
        errorToster(
          t("common_components.extend_stay_popup.toast.extented_stay")
        );
        return;
      }

      let res;
      if (isRemovePerson) {
        res = await api.post(END_POINT.ADDON.UPDATE_EXTRA_PERSON, {
          extra_persons: processedPersons,
        });
      } else {
        formDataInstance.append(
          "extra_persons",
          JSON.stringify({
            extra_persons: processedPersons,
            occupancy_key,
            addon_checkin_date,
          })
        );
        res = await api.postFormData(
          END_POINT.ADDON.ADD_EXTRA_PERSON,
          formDataInstance
        );
      }

      if (res?.status === 200) {
        successToster(
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                `common_components.extra_person_popup.toast.${
                  isRemovePerson
                    ? "person_removed_success"
                    : "person_added_success"
                }`,
                { roomNo: room_no }
              ),
            }}
          />
        );
        dispatch(setTriggered(!isTriggered));
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error in addOrUpdatePerson:", error);
      errorToster(t("common_components.alert.something_went_wrong"));
    } finally {
      setIsSubmitted(false);
    }
  };

  const debouncedAddOrUpdatePerson = useCallback(
    debounce((data) => {
      addOrUpdatePerson(data);
    }, 1000),
    []
  );

  return (
    <>
      <Box
        sx={{
          maxHeight: { sm: "60vw", md: "50vw" },
          overflowY: "auto !important",
          width: { xs: "88vw", sm: "60vw", md: "40vw" },
          padding: { xs: "0.8% 0 2% 0", md: 0 },
        }}
      >
        <Box
          sx={{
            width: "94%",
            margin: "0 auto 2% auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "4.78vw",
                sm: "3vw",
                fontWeight: "600",
                md: "1.389vw",
                color: "#252525",
              },
            }}
          >
            {modalName === "Add Person"
              ? t("common_components.extra_person_popup.add_person")
              : t("common_components.extra_person_popup.remove_person")}
          </Typography>

          <IconButton
            sx={{ m: "1% 0" }}
            size="small"
            onClick={() => setOpenCamera(true)}
            disabled={isRemovePerson}
          >
            <Badge
              sx={{
                "& .MuiBadge-badge": {
                  color: "#DFA828",
                  backgroundColor: "#ECE4C7",
                  fontSize: { xs: 12, sm: "1.44vw", md: "0.83vw" },
                },
              }}
              showZero
              badgeContent={
                defaultCamImage?.length || capturedImage?.length || 0
              }
            >
              <Box
                sx={{
                  height: { xs: "5.38vw", sm: "2.512vw", md: "1.456vw" },
                  width: { xs: "7.18vw", sm: "3.36vw", md: "1.944vw" },
                }}
              >
                <img
                  src={IconConstants.Camera}
                  alt=""
                  style={{ height: "100%", width: "100%" }}
                />
              </Box>
            </Badge>
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "0.694vw",
            width: "94%",
            margin: "1% auto",
          }}
        >
          <Box
            sx={{
              border: "0.5px solid #DEA829",
              borderRadius: "50%",
              width: {
                xs: "8vw",
                sm: "3.597vw",
                md: "2.5vw",
              },
              height: {
                xs: "8vw",
                sm: "3.597vw",
                md: "2.5vw",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "2.56vw",
                  sm: "1.199vw",
                  md: "0.694vw",
                },
                color: "#252525",
                fontWeight: 600,
              }}
            >
              {data?.room_no || "-"}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: {
                xs: "4.667vw",
                sm: "2.5vw",
                fontWeight: 500,
                md: "1.111vw",
                color: "#DFA928",
              },
            }}
          >
            {data?.name}
          </Typography>
        </Box>
        {extra_persons.length > 0 ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                width: { sm: "69%", md: "75%" },
                margin: "0 auto",
              }}
            >
              {fields.map((_, countInd) => (
                <Fragment key={countInd}>
                  {formFields(countInd).loopFields.map(
                    (formField, fieldIndex) => (
                      <FormControl
                        key={fieldIndex}
                        sx={{ width: formField?.width, marginTop: "4.5%" }}
                      >
                        {formField.name ===
                          `extra_persons.${countInd}.addon_name` && (
                          <Box
                            sx={{
                              position: "absolute",
                              left: "-18%",
                              top: "20%",
                              borderRadius: "1.389vw",
                              backgroundColor: primaryColor,
                              height: { sm: "2.111vw", md: "1.806vw" },
                              width: { sm: "2.111vw", md: "1.806vw" },
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "4.1vw",
                                  sm: "1.5vw",
                                  md: "0.764vw",
                                  fontWeight: 700,
                                  color: contrastText,
                                },
                              }}
                            >
                              {countInd + 1}
                            </Typography>
                          </Box>
                        )}
                        <Controller
                          {...methods}
                          name={formField.name}
                          defaultValue=""
                          render={({ field, fieldState: { error } }) => {
                            return renderField(formField, field, error);
                          }}
                        />
                        {formField.name ===
                          `extra_persons.${countInd}.addon_price` && (
                          <Box
                            sx={{
                              position: "absolute",
                              right:
                                countInd === fields.length - 1 &&
                                fields.length !== 1 &&
                                fields.length < 10 &&
                                modalName === "Add Person"
                                  ? "-36%"
                                  : fields.length === 1
                                  ? "-18%"
                                  : "-18%",
                              top:
                                countInd === fields.length - 1 &&
                                fields.length < 10 &&
                                modalName === "Add Person"
                                  ? "10%"
                                  : "5%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {countInd === fields.length - 1 &&
                              fields.length < 10 &&
                              modalName === "Add Person" && (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    append({ addon_price: person_base_price });
                                  }}
                                  disabled={
                                    hasEmptyFields(
                                      !!extra_persons
                                        ? extra_persons[fields.length - 1]
                                        : {}
                                    ) || fields.length > 4
                                  }
                                >
                                  <AddCircleOutline
                                    fontSize="small"
                                    sx={{
                                      color: hasEmptyFields(
                                        !!extra_persons
                                          ? extra_persons[fields.length - 1]
                                          : {}
                                      )
                                        ? "gray"
                                        : "#E2A81F",
                                      fontSize: {
                                        sm: "2.389vw",
                                        md: "1.806vw",
                                      },
                                    }}
                                  />
                                </IconButton>
                              )}
                            {fields.length > 1 && (
                              <IconButton
                                onClick={() => handleRemove(countInd)}
                                size="small"
                              >
                                <RemoveCircleTwoTone
                                  fontSize="small"
                                  sx={{
                                    color: "#FF5A3E",
                                    fontSize: { sm: "2.389vw", md: "1.806vw" },
                                  }}
                                />
                              </IconButton>
                            )}
                          </Box>
                        )}
                      </FormControl>
                    )
                  )}
                </Fragment>
              ))}
              {formFields().commonFields.map((formField, fieldIndex) => (
                <FormControl key={fieldIndex} sx={{ width: formField?.width }}>
                  <Controller
                    {...methods}
                    name={formField.name}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => {
                      return renderField(formField, field, error);
                    }}
                  />
                </FormControl>
              ))}
              <Button
                onClick={handleCloseModal}
                variant="outlined"
                sx={{
                  width: "49%",
                  color: "#DAA72C",
                  border: "1px solid #DAA72C",
                  fontSize: { xs: "3.08vw", sm: "1.333vw", md: "1.111vw" },
                  fontWeight: 700,
                  marginTop: "5%",
                  backgroundColor:
                    "linear-gradient(180deg, #E7AC20 0%, rgba(0, 0, 0, 0) 809.09%)",
                }}
              >
                {t("common_components.extra_person_popup.cancel")}
              </Button>
              <Button
                variant="contained"
                disabled={isSubmitted}
                type="submit"
                sx={{
                  width: "49%",
                  color: contrastText,
                  backgroundColor: "#DAA72C",
                  fontSize: { xs: "3.08vw", sm: "1.333vw", md: "1.111vw" },
                  fontWeight: 700,
                  marginTop: "5%",
                }}
              >
                {t("common_components.extra_person_popup.confirm")}
              </Button>
            </Box>
          </form>
        ) : (
          <Box sx={{ textAlign: "center", width: 1, margin: "5% 0" }}>
            <Typography
              sx={{
                fontSize: {
                  xs: "3.08vw",
                  sm: "1.44vw",
                  md: "0.83vw",
                  color: "#989898",
                },
              }}
            >
              No persons to remove
            </Typography>
          </Box>
        )}
      </Box>
      <ConfirmationDlgBox
        open={confirmationDlg}
        confirmFunction={() => {
          setConfirmationDlg(false);
          if (formData) {
            debouncedAddOrUpdatePerson(formData);
          }
        }}
        setOpen={setConfirmationDlg}
        label={confirmationLabel}
      />
      <ReactWebcam
        openWebcam={openCamera}
        getCapturedImage={(data) => {
          setCapturedImage(data);
          setDefaultCamImage(null);
        }}
        closeWebcam={() => {
          setOpenCamera(!openCamera);
        }}
        defaultCamImage={defaultCamImage}
        imageName={"addon_person_image"}
      />
    </>
  );
};

export default AddOrRemovePerson;
