import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useTheme } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ConfirmationDlgBox = (props) => {
  const { isButtonDisabled } = props;
  const themeStyles = useTheme();
  const { t } = useTranslation();
  let primaryColor = themeStyles.palette.orange_theme.primary.main;
  let contrastText = themeStyles.palette.orange_theme.primary.contrastText;
  const handleClose = () => {
    props?.setOpen(false);
    !!props?.setCustomerId && props?.setCustomerId(null);
    !!props?.setIsSubmitted && props?.setIsSubmitted(false);
  };
  const handleConfirm = () => {
    props?.confirmFunction();
  };
  const [isYesButtonDisabled, setIsYesButtonDisabled] = useState(
    isButtonDisabled || false
  );

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props?.open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props?.open}>
          <Box
            sx={{
              height: { xs: "40vw", sm: "26vw", md: "16vw" },
              width: { xs: "90vw", sm: "64vw", md: "42vw" },
              borderRadius: { xs: "15px", sm: "20px" },
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              outline: "none",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: props?.color || "#CE8500",
                  fontWeight: 500,
                  justifyContent: "center",
                  fontSize: { xs: "3.59vw", sm: "1.918vw", md: "1.389vw" },
                  whiteSpace: "pre-line",
                }}
              >
                {props?.label}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: { xs: "55%", sm: "50%" },
                  margin: "6% auto 0 auto",
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  sx={{
                    width: "48%",
                    color: primaryColor,
                    border: `1px solid ${primaryColor}`,
                    textTransform:"none",
                    fontWeight: 600,
                    fontSize: { xs: "3.08vw", sm: "1.92vw", md: "1.111vw" },
                  }}
                >
                  {t("pages.logout.no")}
                </Button>
                <Button
                  disabled={isYesButtonDisabled}
                  onClick={handleConfirm}
                  variant="contained"
                  sx={{
                    width: "48%",
                    color: contrastText,
                    backgroundColor: primaryColor,
                    textTransform:"none",
                    fontWeight: 600,
                    fontSize: { xs: "3.08vw", sm: "1.92vw", md: "1.111vw" },
                  }}
                >
                  {t("pages.logout.yes")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ConfirmationDlgBox;
