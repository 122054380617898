import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Modal,
  Fade,
  Popover,
  Button,
} from "@mui/material";
import { format, addDays } from "date-fns";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import IconConstants from "../../utils/IconConstants";
import { END_POINT } from "../../utils/End-points";
import ApiClient from "../../apis/Api-client";
import { Helper } from "../../utils/Helpers";
import {
  OCCUPANCY_STATUSES,
  RESERVATION_STATUSES,
  USER_ROLES,
} from "../../utils/Constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthData } from "../../core/store/redux/slices/Auth-slice";
import { setDashBoardData } from "../../core/store/redux/slices/Dashboard-slice";
import RoomShift from "../../components/roomshift/Room-shift";
import ExtendStay from "../../components/extendstay/Extend-stay";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CancelReservationModal from "../reservation/CancelReservationModal";
const { getLocalTimeFromUtc, getAuthorizedObjects } = new Helper();
const { CHECK_IN } = OCCUPANCY_STATUSES;
const { CONFIRMED, TENTATIVE } = RESERVATION_STATUSES;
const { ADMIN, RECEPTIONIST } = USER_ROLES;

const houseKeepingIcons = {
  clean: IconConstants.CleanedCal,
  assigned: IconConstants.AssignedCal,
  dirty: IconConstants.DirtyCal,
};

const WeekView = ({ weekStart, roomTypeData, isExpanded, isEnglish }) => {
  const { api } = ApiClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = new URLSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const openpop = Boolean(anchorEl);
  const id = openpop ? "simple-popover" : undefined;
  const { role_name } = useSelector(selectAuthData).user;
  const [events, setEvents] = useState([]);
  const [expandedRoomTypes, setExpandedRoomTypes] = useState({});
  const [modalContent, setModalContent] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [activeRowData, setActiveRowData] = useState({});

  const addNoRoomReservation = (roomTypeData) => {
    return roomTypeData.map((roomType) => {
      const noRoom = {
        room_id: roomType.room_type_key + roomType.room_type_id,
        room_no: "",
      };
      const updatedRooms = [...roomType.rooms, noRoom];
      return {
        ...roomType,
        rooms: updatedRooms,
      };
    });
  };

  const updatedRoomTypeData = addNoRoomReservation(roomTypeData);

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setActiveRowData({});
  };

  useEffect(() => {
    getAllBookings();
  }, []);

  const getAllBookings = async () => {
    try {
      const response = await api.post(END_POINT.BOOKING.GET_ALL_BOOKING, {});
      if (response.status === 200) {
        const { data } = response?.data;
        if (data?.length) {
          const updatedData = data
            .map((v) => {
              const localCheckInTime = getLocalTimeFromUtc(
                v.checkin_date
              ).dateString;
              const localCheckoutTime = getLocalTimeFromUtc(
                v.checkout_date
              ).dateString;

              return {
                ...v,
                roomId: v.room_id || null,
                checkin: localCheckInTime,
                checkout: localCheckoutTime,
                status: v.booking_status,
                customerName: v.name,
              };
            })
            .sort((a, b) => {
              const intervalA =
                new Date(a.checkout).getTime() - new Date(a.checkin).getTime();
              const intervalB =
                new Date(b.checkout).getTime() - new Date(b.checkin).getTime();
              return intervalB - intervalA;
            });
          setEvents(updatedData);
        }
      }
    } catch (error) {}
  };

  const daysOfWeek = Array.from({ length: 7 }).map((_, index) =>
    format(addDays(weekStart.setHours(0, 0, 0, 0), index), "EEEE dd MMMM")
  );

  const weekEnd = addDays(weekStart, 6);

  const toggleExpand = (roomType) => {
    setExpandedRoomTypes((prev) => ({
      ...prev,
      [roomType]: !prev[roomType],
    }));
  };

  useEffect(() => {
    if (roomTypeData?.length > 0) {
      setExpandedRoomTypes({
        [roomTypeData[0].room_type_name]: true,
      });
    }
  }, [roomTypeData]);

  const normalizeDate = (date) => {
    return moment(date).startOf("day").toDate();
  };

  const getCellStatus = (roomId, date, weekStart, weekEnd) => {
    const normalizedDate = normalizeDate(date);

    const bookingsForDate = events.filter((b) => {
      const checkinDate = normalizeDate(b.checkin);
      const checkoutDate = normalizeDate(b.checkout);
      return (
        (!!b.roomId
          ? b.roomId === roomId
          : roomId === b.room_type_key + b.room_type_id) &&
        normalizedDate >= checkinDate &&
        normalizedDate <= checkoutDate
      );
    });

    return bookingsForDate.map((booking) => {
      const checkinDate = normalizeDate(booking.checkin);
      const isFirstVisibleCell =
        checkinDate >= weekStart && checkinDate <= weekEnd
          ? normalizedDate.getTime() === checkinDate.getTime()
          : normalizedDate.getTime() === weekStart.getTime();

      return {
        ...booking,
        isFirstVisibleCell,
      };
    });
  };

  const getEventBarStyle = (status, key) => {
    return {
      backgroundColor: (() => {
        const isActive = activeRowData?.reservation_key === key;
        if (status === CHECK_IN) {
          return isActive ? "#ED705B" : "#FFE7E4";
        }
        return isActive ? "#9747FF" : "#F3EAFF";
      })(),
      color:
        activeRowData?.reservation_key === key
          ? "#FFFFFF"
          : status === CHECK_IN
          ? "#ED705B"
          : "#9747FF",
      width: "calc(100% + 2px)",
      marginLeft: "-1px",
      marginRight: "-1px",
      zIndex: 1,
      height: { xs: "5.972vw", sm: "3.597vw", md: "2.083vw" },
      display: "flex",
      alignItems: "center",
      mt: { xs: "1vw", sm: "0.719vw", md: "0.417vw" },
      pl: "4%",
      borderTop: (() => {
        const isActive = activeRowData?.reservation_key === key;
        if (status === CHECK_IN) {
          return `0.139vw solid ${isActive ? "#FFE7E4" : "#ED705B"}`;
        }
        return `0.139vw solid ${isActive ? "#F3EAFF" : "#9747FF"}`;
      })(),

      cursor: "pointer",
    };
  };

  const optionsData = [
    {
      label: "Check In",
      icon: IconConstants.CheckinGray,
      action: () => {
        setTimeout(() => {
          params.append("reservation_key", activeRowData?.reservation_key);
          navigate(`/main/arrival/reservedcheckin?${params.toString()}`);
        });
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Check Out",
      icon: IconConstants.CheckinGray,
      action: () => {
        setTimeout(() => {
          params.append(
            "occupancy_keys",
            JSON.stringify([
              activeRowData.parent_occupancy_key ?? activeRowData.occupancy_key,
            ])
          );
          navigate(`/main/departure?${params.toString()}`);
        });
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Guest Details",
      icon: IconConstants.GuestDetailsGray,
      action: () => {
        const { reservation_key, occupancy_key } = activeRowData;
        dispatch(setDashBoardData({ occupancy_key, reservation_key }));
        setTimeout(() => {
          navigate(
            `/main/${
              activeRowData?.booking_status === CHECK_IN
                ? "guestinfo"
                : "reservations"
            }`
          );
        });
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Room Shift",
      icon: IconConstants.RoomShiftGray,
      action: () => {
        setModalContent("roomShift");
        setOpenModal(true);
        setAnchorEl(null);
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Extend Stay",
      icon: IconConstants.ExtendStayGray,
      action: () => {
        setModalContent("extendStay");
        setOpenModal(true);
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Cancel Reservation",
      icon: IconConstants.CalendarCancel,
      activeIcon: IconConstants.CancelWhite,
      action: () => {
        setModalContent("cancelReservation");
        setOpenModal(true);
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
  ];

  const handleClosePopover = () => {
    setOpenModal(false);
    setAnchorEl(null);
    setActiveRowData({});
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setActiveRowData({});
  };

  const handleStatusBarClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setActiveRowData(data);
  };

  const handleCellClick = (data) => {
    const { room_key, selectedDate } = data;
    const isToday = selectedDate === new Date().setHours(0, 0, 0, 0);
    if (isToday) {
      params.append("room_key", room_key);
      navigate(`/main/arrival?${params.toString()}`);
    } else {
      params.append("room_key", room_key);
      params.append(
        "checkin_date",
        format(new Date(selectedDate), "yyyy-MM-dd")
      );
      navigate(`/main/reservation?${params.toString()}`);
    }
  };

  return (
    <>
      <TableContainer
        component={Box}
        sx={{
          maxHeight: isExpanded
            ? { xs: "68vh", sm: "86.331vw", md: "76vh" }
            : { xs: "34.5vh", sm: "51vw", md: "55vh" },
          overflowY: "auto",
          border: "1px solid #FFDC8A",
          borderRadius: "12px",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: "0.5px solid #FCF0D4",
                  width: { xs: "21.795vw", sm: "14.149vw", md: "8.194vw" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "5.129vw", sm: "3.837vw", md: "2.222vw" },
                      height: { xs: "5.129vw", sm: "3.837vw", md: "2.222vw" },
                    }}
                  >
                    <img
                      src={IconConstants.RoomsCal}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "2.565vw", sm: "1.918vw", md: "1.111vw" },
                      color: "#D8A630",
                      fontWeight: 600,
                    }}
                  >
                    {t("pages.home_page.room_view_section.rooms")}
                  </Typography>
                </Box>
              </TableCell>
              {daysOfWeek.map((weekDay) => {
                const [day, date, month] = weekDay.split(" ");
                return (
                  <TableCell
                    key={weekDay}
                    align="center"
                    sx={{
                      border: "0.5px solid #FCF0D4",
                      padding: { sm: "1% 0" },
                      width: { xs: "22.051vw", sm: "9.832vw", md: "5.694vw" },
                    }}
                  >
                    <>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "3.077vw",
                            sm: "2.399vw",
                            md: "1.389vw",
                          },
                          fontWeight: 700,
                          color: "#D8A630",
                        }}
                      >
                        {date}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "2.565vw",
                            sm: "1.439vw",
                            md: "0.833vw",
                          },
                          color: "#252525",
                          textWrap: "nowrap",
                        }}
                      >
                        {isEnglish
                          ? `${t(`days.${day.toLowerCase()}`).slice(
                              0,
                              3
                            )} | ${t(`months.${month.toLowerCase()}`).slice(
                              0,
                              3
                            )}`
                          : `${t(`days.${day.toLowerCase()}`)} | ${t(
                              `months.${month.toLowerCase()}`
                            )}`}

                        {/* {day} | {month} */}
                      </Typography>
                    </>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {updatedRoomTypeData?.map((group) => (
              <Fragment key={group.room_type_name}>
                <TableRow>
                  <TableCell sx={{ border: "0.5px solid #FCF0D4" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#D8A630",
                          fontSize: {
                            xs: "2.565vw",
                            sm: "1.679vw",
                            md: "0.972vw",
                          },
                          fontWeight: 600,
                        }}
                      >
                        {group.room_type_name}
                      </Typography>
                      <IconButton
                        size="small"
                        sx={{ p: 0, ml: "4%" }}
                        onClick={() => toggleExpand(group.room_type_name)}
                      >
                        {expandedRoomTypes[group.room_type_name] ? (
                          <ArrowDropUp sx={{ color: "#AAAAAA" }} />
                        ) : (
                          <ArrowDropDown sx={{ color: "#AAAAAA" }} />
                        )}
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>

                {expandedRoomTypes[group.room_type_name] &&
                  group.rooms.map((room) => (
                    <TableRow key={room.room_id}>
                      <TableCell sx={{ border: "0.5px solid #FCF0D4" }}>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "1vw",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#515151",
                                fontSize: {
                                  xs: "2.565vw",
                                  sm: "1.679vw",
                                  md: "0.972vw",
                                },
                                fontWeight: 500,
                              }}
                            >
                              {room.room_no || "Without Room"}
                            </Typography>

                            {!!room.room_no ? (
                              <Box>
                                <Box
                                  sx={{
                                    height: {
                                      xs: "5.642vw",
                                      sm: "3.597vw",
                                      md: "2.083vw",
                                    },
                                    width: {
                                      xs: "5.642vw",
                                      sm: "3.597vw",
                                      md: "2.083vw",
                                    },
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={
                                      houseKeepingIcons[
                                        room.cleaning_status_code
                                      ]
                                    }
                                    loading="lazy"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </Box>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Box>
                      </TableCell>

                      {Array.from({ length: 7 }).map((_, index) => {
                        const date = addDays(weekStart, index);

                        const bookingInfo = getCellStatus(
                          room.room_id,
                          date,
                          weekStart,
                          weekEnd
                        );
                        const selectedDate = date.setHours(0, 0, 0, 0);
                        const isPastDate =
                          selectedDate < new Date().setHours(0, 0, 0, 0);
                        const isClickable =
                          bookingInfo.length === 0 && !isPastDate;
                        return (
                          <TableCell
                            key={index}
                            sx={{
                              border: "0.5px solid #FCF0D4",
                              textAlign: "center",
                              position: "relative",
                              padding: 0,
                              verticalAlign: "top",
                              cursor: isClickable ? "pointer" : "default",
                            }}
                            onClick={() =>
                              isClickable &&
                              handleCellClick({
                                room_key: room.room_key,
                                booking_status: room.booking_status,
                                selectedDate,
                              })
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                minHeight: { sm: "7.194vw", md: "6.167vw" },
                              }}
                            >
                              {bookingInfo.map((booking, bookingIndex) => (
                                <Box
                                  key={bookingIndex}
                                  sx={{
                                    ...getEventBarStyle(
                                      booking.status,
                                      booking.reservation_key
                                    ),
                                  }}
                                  onClick={(event) =>
                                    handleStatusBarClick(event, booking)
                                  }
                                >
                                  {booking.isFirstVisibleCell && (
                                    <Typography
                                      sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: {
                                          xs: "2.308vw",
                                          sm: "1.439vw",
                                          md: "0.833vw",
                                        },
                                        width: {
                                          xs: "15.051vw",
                                          sm: "9.832vw",
                                          md: "5.694vw",
                                        },
                                      }}
                                    >
                                      {booking.customerName}
                                    </Typography>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "4px",
          },
        }}
      >
        <Box
          sx={{
            width: { xs: "40vw", sm: "23vw", md: "15.2vw" },
            padding: "7% 4%",
          }}
        >
          {getAuthorizedObjects(optionsData, role_name)
            .filter((option) => {
              const isCheckInStatus =
                activeRowData?.booking_status === CHECK_IN;
              const isReservedStatus = [CONFIRMED, TENTATIVE].includes(
                activeRowData?.booking_status
              );

              const isCheckInPastDate =
                activeRowData?.checkin &&
                new Date(activeRowData?.checkin).setHours(0, 0, 0, 0) <
                  new Date().setHours(0, 0, 0, 0);

              const isCheckInToday =
                activeRowData?.checkin &&
                new Date(activeRowData?.checkin).toDateString() ===
                  new Date().toDateString();

              const hideForRoomNo =
                !activeRowData?.room_no &&
                ["Room Shift"].includes(option.label);

              if (isReservedStatus) {
                if (isCheckInToday) {
                  return !["Check Out"].includes(option.label);
                } else if (isCheckInPastDate) {
                  return ["Guest Details", "Cancel Reservation"].includes(
                    option.label
                  );
                } else {
                  return !["Check In", "Check Out"].includes(option.label);
                }
              }
              return isCheckInStatus
                ? !["Check In", "Cancel Reservation"].includes(option.label)
                : !["Check Out"].includes(option.label) && !hideForRoomNo;
            })
            .map((val, ind) => (
              <Button
                key={ind}
                fullWidth
                sx={{
                  paddingY: "4%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  textTransform: "none",
                  textWrap: "nowrap",
                }}
                onClick={() => {
                  val.action(ind);
                }}
                endIcon={
                  <Box
                    sx={{
                      width: { xs: "5.129vw", sm: "2.398vw", md: "1.389vw" },
                      height: { xs: "5.129vw", sm: "2.398vw", md: "1.389vw" },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={val.icon}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                }
              >
                <Typography
                  sx={{
                    fontSize: { xs: "2.821vw", sm: "1.679vw", md: "1.111vw" },
                    color: "#5A5A5A",
                  }}
                >
                  {t(
                    `pages.home_page.room_view_section.${val.label
                      .toLowerCase()
                      .replace(/ /g, "_")}`
                  )}
                </Typography>
              </Button>
            ))}
        </Box>
      </Popover>

      <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={openModal}>
          <Box
            sx={{
              minWidth: modalContent === "extendStay" && {
                xs: "60%",
                md: "35%",
              },
              borderRadius: "16px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              padding: "1.5%",
              zIndex: 20,
            }}
          >
            {modalContent === "roomShift" && (
              <RoomShift
                bookingData={activeRowData}
                handleCloseModal={handleCloseModal}
                handleClosePopover={handleClosePopover}
                resetData={getAllBookings}
              />
            )}
            {modalContent === "extendStay" && (
              <ExtendStay
                bookingData={activeRowData}
                handleCloseModal={handleCloseModal}
                handleClosePopover={handleClosePopover}
                resetData={getAllBookings}
              />
            )}

            {modalContent === "cancelReservation" && (
              <CancelReservationModal
                bookingData={activeRowData}
                handleCloseModal={handleCloseModal}
                handleClosePopover={handleClosePopover}
                reset={getAllBookings}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default WeekView;
