import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal, Box, IconButton, Button, Fade, Backdrop } from "@mui/material";
import Webcam from "react-webcam";
import "./React-webcam.scss";
import Compressor from "compressorjs";
import {
  CameraswitchRounded,
  CancelOutlined,
  CloudUpload,
} from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import IconConstants from "../../../utils/IconConstants";
import { errorToster, warnToster } from "../../../shared/toster/Toster";
import { useTranslation } from "react-i18next";

const ReactWebcam = ({
  clearWebcamData,
  openWebcam,
  getCapturedImage,
  closeWebcam,
  defaultCamImage = [],
  imageName,
}) => {
  const { t } = useTranslation();
  const webcamRef = useRef(null);
  const [capturedImages, setCapturedImages] = useState([]);
  const [imagePng, setImagePng] = useState([]);
  const [tempCapturedImages, setTempCapturedImages] = useState([]);
  const [tempImagePng, setTempImagePng] = useState([]);
  const [open, setOpen] = useState(false);
  const [isCameraAvailable, setisCameraAvailable] = useState(false);
  const [tempImage, setTempImage] = useState(null);
  const [facingMode, setFacingMode] = useState("environment");
  const fileInputRef = useRef(null);
  const fileUpload = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    setOpen(openWebcam);
    if (openWebcam) {
      setTempCapturedImages([
        ...capturedImages,
        ...(defaultCamImage?.map((v) => v?.url) || []),
      ]);
      setTempImagePng([
        ...imagePng,
        ...(defaultCamImage?.map((v) => v?.key) || []),
      ]);
    }
  }, [openWebcam]);

  useEffect(() => {
    if (clearWebcamData) {
      setCapturedImages([]);
      setImagePng([]);
      setTempCapturedImages([]);
      setTempImagePng([]);
    }
  }, [clearWebcamData]);

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setTempImage(imageSrc);
  }, [webcamRef]);

  const confirmImage = () => {
    formatIntoPng(tempImage)
      .then((res) => {
        setTempCapturedImages((prevImages) => [...prevImages, tempImage]);
        setTempImagePng((prevImages) => [...prevImages, res]);
        setTempImage(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const rejectImage = () => {
    setTempImage(null);
  };

  const toggleFacingMode = () => {
    setFacingMode(facingMode === "user" ? "environment" : "user");
  };

  const handleConfirmation = () => {
    if (!tempCapturedImages?.length) {
      errorToster(t("pages.booking_form.alert.please_upload_a_image"));
    } else {
      setOpen(false);
      closeWebcam();
    }
    setCapturedImages(tempCapturedImages);
    setImagePng(tempImagePng);
    getCapturedImage(tempImagePng);
  };

  const handleClose = () => {
    setTempCapturedImages(capturedImages);
    setTempImagePng(imagePng);
    setTempImage(null);
    setOpen(false);
    closeWebcam();
    setisCameraAvailable(false);
  };

  const handleWebcamReady = (userMedia) => {
    if (userMedia?.active) {
      setisCameraAvailable(true);
    } else {
      setisCameraAvailable(false);
    }
  };

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    const newTempImages = [...tempCapturedImages];
    const newTempImagePngs = [...tempImagePng];

    // Valid image MIME types
    const validImageTypes = [
      "image/jpeg",
      "image/png",
      "image/heif",
      "image/heic",
    ];

    Array.from(files)?.forEach((file) => {
      // Validate file type
      if (!validImageTypes.includes(file.type)) {
        errorToster(t("pages.booking_form.toast.select_valid_image"));
        return;
      }

      // Validate file size
      if (file.size > MAX_FILE_SIZE) {
        errorToster(t("pages.booking_form.toast.file_size_exceed"));
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        newTempImages.push(reader.result);
        setTempCapturedImages(newTempImages);

        formatIntoPng(reader.result)
          .then((res) => {
            newTempImagePngs.push(res);
            setTempImagePng(newTempImagePngs);
          })
          .catch((error) => console.error(error));
      };
      reader.readAsDataURL(file);
    });
  };

  const formatIntoPng = (dataURL) => {
    return new Promise((resolve, reject) => {
      const file = dataURLtoFile(dataURL, imageName);
      new Compressor(file, {
        quality: 0.85,
        maxWidth: 900,
        maxHeight: 900,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl?.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr?.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr?.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const retake = (index) => {
    const updatedTempImages = [...tempCapturedImages];
    updatedTempImages.splice(index, 1);
    setTempCapturedImages(updatedTempImages);

    const updatedTempImagePng = [...tempImagePng];
    updatedTempImagePng.splice(index, 1);
    setTempImagePng(updatedTempImagePng);
  };

  const handleFileInputCapture = (event) => {
    const file = event?.target?.files[0];

    // Valid image MIME types
    const validImageTypes = [
      "image/jpeg",
      "image/png",
      "image/heif",
      "image/heic",
    ];

    // Validate file type
    if (!validImageTypes.includes(file.type)) {
      errorToster(t("pages.booking_form.toast.select_valid_image"));
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setTempImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            width: { xs: "91.795vw", sm: "60%", md: "37%" },
            height: { xs: "111.282vw", sm: "auto", md: "auto" },
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: { xs: "24px", sm: "25px", md: "36px" },
            textAlign: "center",
            padding: { xs: "5% 1%", md: "3% 1%" },
          }}
        >
          {/* Show webcam if no temporary image */}
          {!tempImage && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {/* <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="circular-webcam"
                mirrored={false}
                onUserMedia={handleWebcamReady}
                videoConstraints={{ facingMode }}
              /> */}
              <Box
                sx={{
                  margin: "2% 0",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: { xs: "23%", sm: "27%" } }}> </Box>
                <Box sx={{ width: "40%", margin: "10% auto" }}>
                  <IconButton
                    // disabled={!isCameraAvailable}
                    // onClick={capture}
                    onClick={() => fileUpload?.current?.click()}
                    size="small"
                  >
                    <Box
                      sx={{
                        height: { xs: "35.36vw", sm: "8.63vw", md: "5vw" },
                        width: { xs: "35.36vw", sm: "8.63vw", md: "5vw" },
                      }}
                    >
                      <img
                        src={IconConstants.Capture}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  </IconButton>

                  <input
                    accept="image/jpeg,image/png,image/heif,image/heic"
                    type="file"
                    capture={facingMode}
                    ref={fileUpload}
                    onChange={handleFileInputCapture}
                    style={{ display: "none" }}
                  />
                </Box>

                <Box sx={{ width: "30%" }}>
                  {/* <IconButton
                    onClick={toggleFacingMode}
                    sx={{ display: { md: "none" } }}
                    size="small"
                  >
                    <CameraswitchRounded />
                  </IconButton> */}
                </Box>
              </Box>
            </Box>
          )}

          {/* Confirmation for captured image */}
          {tempImage && (
            <Box sx={{ textAlign: "center" }}>
              <img src={tempImage} alt="Captured" className="circular-webcam" />
              <Box
                sx={{
                  width: "60%",
                  margin: "3% auto",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  top: { xs: "7.692vw", sm: "auto" },
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  onClick={rejectImage}
                  sx={{
                    color: "#ED705B",
                  }}
                  size="small"
                >
                  <Box
                    sx={{
                      height: { xs: "10.685vw", sm: "3.95vw", md: "2.29vw" },
                      width: { xs: "10.685vw", sm: "3.95vw", md: "2.29vw" },
                    }}
                  >
                    <img
                      src={IconConstants.CloseCircle}
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </IconButton>
                <IconButton
                  onClick={confirmImage}
                  sx={{ color: "#62BA60" }}
                  size="small"
                >
                  <Box
                    sx={{
                      height: { xs: "10.685vw", sm: "3.95vw", md: "2.29vw" },
                      width: { xs: "10.685vw", sm: "3.95vw", md: "2.29vw" },
                    }}
                  >
                    <img
                      src={IconConstants.TickCircle}
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </IconButton>
              </Box>
            </Box>
          )}

          {/* Display captured images */}
          <Box
            sx={{
              display: isMobile && tempImage ? "none" : "flex",
              justifyContent: isMobile ? "space-around" : "flex-start",
              margin: "2% auto",
              width: "88%",
              overflow: "scroll",
            }}
          >
            {tempCapturedImages?.map((image, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    margin: "3% 2%",
                  }}
                >
                  <Box
                    component="img"
                    src={image}
                    alt="Captured"
                    sx={{
                      width: { xs: "15.385vw", sm: "70px", md: "70px" },
                      height: { xs: "15.385vw", sm: "70px", md: "70px" },
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                  <IconButton
                    size="small"
                    sx={{ position: "absolute", top: "-14px", right: "-14px" }}
                    onClick={() => retake(index)}
                    color="#707070"
                  >
                    <CancelOutlined fontSize="small" />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
          {!tempImage && (
            <Box
              sx={{
                display: "flex",
                position: isMobile ? "fixed" : "",
                bottom: isMobile ? "8%" : "auto",
                left: isMobile ? "50%" : "",
                transform: isMobile ? "translateX(-50%)" : "none",
                justifyContent: "space-between",
                width: "85%",
                margin: "0 auto",
              }}
            >
              {/* Upload from file input */}
              <Box
                sx={{
                  width: "45%",
                }}
              >
                <Button
                  fullWidth
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "3.59vw", sm: "1.92vw", md: "1.12vw" },
                    color: "#E7AC20",
                    border: "1px solid #E7AC20",
                  }}
                  onClick={() => fileInputRef.current.click()}
                  startIcon={
                    <CloudUpload sx={{ color: "#E7AC20" }} fontSize="small" />
                  }
                  variant="outlined"
                >
                  {t("pages.booking_form.upload")}
                </Button>
                <input
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/jpeg,image/png,image/heif,image/heic"
                  multiple
                  onChange={handleFileInputChange}
                />
              </Box>
              <Button
                onClick={handleConfirmation}
                sx={{
                  fontWeight: 600,
                  width: "45%",
                  fontSize: { xs: "3.59vw", sm: "1.92vw", md: "1.12vw" },
                  color: "white",
                  backgroundColor: "#E7AC20",
                }}
                variant="contained"
              >
                {t("pages.booking_form.confirm")}
              </Button>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ReactWebcam;
